import styles from "./styles.module.css"

import React from 'react'

const Header = (props) => {
  const{content, onClick, className, space} = props;

  return (
    <>
    <div className={styles.headercontainer}>
      <font className={[styles.header].concat(className).join(" ")} style={{letterSpacing: space, marginRight: "-"+space}} onClick={onClick}>{content}</font>
    </div>
    </>
    
  )
}

export default Header
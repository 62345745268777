import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Header from "../../components/header";
import BackVideo from "../../components/backvideo";
import Button from "../../components/button";
import { useAuth } from "../../contexts/AmplifyAuthContext";
import Profile from "../../components/profile";
import { useNavigate } from "react-router-dom";
import { addOrganization, deleteMilicastVideo, deleteSessions, getAllSessions, getCompositeSessionsByStreamName, getOrganizations, getSessionsByStreamName, getTelemetryURL } from "../../api";
import api from "../../timestream";
import { Storage } from "aws-amplify";
import CollapseMenu from "./collapse-menu";
import { GiAerialSignal } from "react-icons/gi";
import { FaPlay, FaShare } from "react-icons/fa";
import { BsFileEarmarkBarGraphFill } from "react-icons/bs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiFillDelete, AiOutlineCloseCircle } from "react-icons/ai";
import VideoPlayer from "./video-player";
import Dropdown from "./drop-down";
import DialogBoxForm from "./dialog-form";
import DialogBoxSearch from "./dialog-search";
import DialogBoxDownload from "./dialog-download";

function isoToTs(iso) {
    return new Date(iso).getTime();
}

let gotMap = new Map();
let loadedMap = new Map();

export default function CustomerJourney(props) {
    const { userDetails, authData, currentUser } = useAuth();
    const [show, setShow] = useState(false);
    const [select, setSelect] = useState(0);
    const [loading, setLoading] = useState(true);
    const [to, setTo] = useState(null);
    const [from, setFrom] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [count, setCount] = useState(0);
    const [display, setDisplay] = useState();
    const [got, setGot] = useState(0);
    const [loaded, setLoaded] = useState(0);

    const navigate = useNavigate();
    // eslint-disable-next-line
    const [name, setName] = useState(userDetails ? userDetails.firstname : "");

    const NameComponent = () => (
        <>
            Hello <span>{name}</span>! What issue are you facing?
        </>
    );

    function compare(a, b) {
        const tsTo = new Date(to);
        const tsFrom = new Date(from);
        const tsSessionA = new Date(a.createdAt);
        const tsSessionB = new Date(a.createdAt);
        if (to && from) {
            if ((tsSessionA > tsFrom && tsSessionA < tsTo) || (tsSessionB > tsFrom && tsSessionB < tsTo)) {
                setCount(count + 1);
            }
        } else {
            setCount(count + 1);
        }

        if (new Date(a.createdAt) < new Date(b.createdAt)) {
            return -1;
        }
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        if (userDetails && !userDetails.customer) {
            navigate("/journey");
        }

        getAllSessions(userDetails.uid, authData.signInUserSession.accessToken.jwtToken)
            .then(async (res) => {
                let data = await res.sort(compare);
                data.reverse()
                
                console.log(data)
                if (currentUser?.attributes?.preferred_username) {
                    try {
                        let data2 = (await getAllSessions(currentUser.attributes.preferred_username, authData.signInUserSession.accessToken.jwtToken)).sort(compare)
                        data2.reverse();
                        console.log(data, data2)
                        data = data.concat(data2);
                    } catch (e) {
                        console.log(e)
                    }
                }

                console.log(data)

                if (!!to && !!from) {
                    const tsTo = new Date(to);
                    const tsFrom = new Date(from);
                    let temp = [];
                    for (let i = 0; i < data.length; i++) {
                        const item = data[i];
                        const tsSessionA = new Date(item.createdAt);
                        if (tsSessionA > tsFrom && tsSessionA < tsTo) {
                            temp.push(item);
                        }
                    }
                    setSessions(temp);
                    setDisplay(temp.slice(0, 10));
                } else {
                    setSessions(data);
                    setDisplay(data.slice(0, 10));
                }

                setLoading(false);
                //await getRecordedFiles(data)
            })
            .catch((err) => {
                console.log(err);
            });

        // if (currentUser?.attributes?.preferred_username) {
        //     getAllSessions(currentUser.attributes.preferred_username, authData.signInUserSession.accessToken.jwtToken)
        //         .then(async (res2) => {
        //             let data2 = await res2.sort(compare);
        //             data2.reverse()
        //             console.log("All data2 ", res2, sessions, data2);
        //             if (!!to && !!from) {
        //                 const tsTo = new Date(to);
        //                 const tsFrom = new Date(from);
        //                 let temp = [];
        //                 for (let i = 0; i < data2.length; i++) {
        //                     const item = data2[i];
        //                     const tsSessionA = new Date(item.createdAt);
        //                     if (tsSessionA > tsFrom && tsSessionA < tsTo) {
        //                         temp.push(item);
        //                     }
        //                 }
        //                 setSessions(sessions.concat(temp));
        //                 setDisplay(sessions.concat(temp).slice(0, 10));
        //             } else {
        //                 setSessions(sessions.concat(data2));
        //                 setDisplay(sessions.concat(data2).slice(0, 10));
        //             }

        //             setLoading(false);
        //             //await getRecordedFiles(data)
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //         });
        // }

        return () => {
            gotMap = new Map();
            loadedMap = new Map();
        };
    }, [userDetails, to, from]);

    useEffect(() => {
        if (!!display && loaded === display.length) {
            if (got % 10 !== 0) {
                let loadmore = 10 - (got % 10);
                const diff = sessions.length - display.length;
                if (diff > loadmore) {
                    setDisplay(sessions.slice(0, display.length + loadmore));
                } else {
                    setDisplay(sessions);
                }
            }
        }
    }, [loaded, got, display]);

    return (
        <>
            <BackVideo videos={["https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.mp4", "https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.webm"]} poster={"https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-poster-00001.jpg"} />
            {show && (
                <>
                    {select === 0 && <DialogBoxSearch setShow={setShow} title={"SEARCH M.O.S."} cta={"READY FOR YOUR MOBILE OPTICAL SYSTEM"} placeholder={"Enter Email"} buttonText={"SEARCH"} navigate={navigate} setToState={setTo} setFromState={setFrom} />}
                    {select === 2 && <DialogBoxDownload setShow={setShow} label={"feature"} path={"newfeaturereqs"} title={"M.O.S."} cta={"GET THE LATEST M.O.S."} placeholder={"Enter Organization"} buttonText={"DOWNLOAD"} />}
                    {select === 4 && <DialogBoxForm setShow={setShow} label={"issue"} path={"issues"} title={"CONTACT"} cta={<NameComponent />} placeholder={"Describe Issue"} buttonText={"SEND"} />}
                </>
            )}
            <div className={styles.home}>
                <Profile />
                <Header content={"DASHBOARD"} space={"3vh"} />
                <CollapseMenu navigate={navigate} setShow={setShow} setSelect={setSelect} />

                {/* <div>{got}-{gotMap.size}-{!!display ? display.length : ""}-{loaded}-{loadedMap.size}</div> */}
                <div className={styles.mosList}>
                    {!!userDetails && sessions.length > 0 && count > 0 && (
                        <>
                            {!loading && (
                                <>
                                    {display.map((session, key) => {
                                        const data = session;
                                        if (to && from) {
                                            const tsTo = new Date(to);
                                            const tsFrom = new Date(from);
                                            const tsSession = new Date(session.createdAt);
                                            if (tsSession > tsFrom && tsSession < tsTo) {
                                                return <SessionItem data={data} id={key} length={sessions.length} sessions={sessions} displayed={display} setDisplayed={setDisplay} setGot={setGot} setLoaded={setLoaded} got={got} loaded={loaded} />;
                                            } else {
                                                return <></>;
                                            }
                                        } else {
                                            return <SessionItem data={data} id={key} length={sessions.length} sessions={sessions} displayed={display} setDisplayed={setDisplay} setGot={setGot} setLoaded={setLoaded} got={got} loaded={loaded} />;
                                        }
                                    })}
                                    {display.length < sessions.length && (
                                        <div>
                                            <Button
                                                onClick={(e) => {
                                                    const diff = sessions.length - display.length;
                                                    if (diff > 10) {
                                                        setDisplay(sessions.slice(0, display.length + 10));
                                                    } else {
                                                        setDisplay(sessions);
                                                    }
                                                }}
                                                content={"Load More"}
                                                className={styles.loadButton}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            {loading && <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontWeight: 300 }}>{"Loading Videos & Telemetry..."}</div>}
                        </>
                    )}
                    {!(!!userDetails && sessions.length > 0 && count > 0) && (
                        <>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontWeight: 300 }}>{!loading ? (count >= 0 ? "No MOS Sessions." : `No MOS session found from ${from} to ${to}`) : "Loading MOS Sessions.."}</div>
                        </>
                    )}
                    <div style={{ marginTop: "2vw" }}></div>
                </div>
                <div style={{ height: "30vh" }}></div>
            </div>
        </>
    );
}

function SessionItem({ data, id, length, sessions, displayed, setDisplayed, setLoaded, setGot, loaded, got }) {
    const { userDetails, authData } = useAuth();
    const [display, setDisplay] = useState(false);
    const [recorded, setRecorded] = useState(null);
    const [elasped, setElasped] = useState(0);
    const [telemetryData, setTelemetryData] = useState({ telemetry: [] });
    const [tempData, setTempData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [toggle, setToggle] = useState(false);
    const [fromS3, setFromS3] = useState(false);
    const [telemetry, setTelemetry] = useState();
    const [csvUrl, setCsvUrl] = useState();
    const [csv, setCsv] = useState();
    const [recordLoading, setRecordLoading] = useState(true);
    const [ddownToggle, setDdownToggle] = useState(false);
    const [gotCsv, setGotCsv] = useState(false);
    const [gotJson, setGotJson] = useState(false);
    const [composite, setComposite] = useState();
    const [compositeDisplay, setCompositeDisplay] = useState(false);
    const [islive, setIslive] = useState(false);
    const [copied, setCopied] = useState(false);

    const navigate = useNavigate();
    const token = authData?.signInUserSession?.accessToken?.jwtToken || 'failed to get token';

    const getRecordedSessions = async () => {
        const end = new Date(data.updatedAt);
        const now = new Date(Date.now());

        const diff = now - end;
        const seconds = Math.floor(diff / 1e3);

        if (data.status) {

            gotMap.set(data.sessionId, true);
            await setGot(gotMap.size);
            await setIslive(true);
        } else {
            const res = await getSessionsByStreamName(data.sessionId, token);
            console.log(res);

            const testObj = Object.keys(res).length > 0;
            if (testObj) {
                setRecorded(res);
                if (!!res) {
                    gotMap.set(data.sessionId, true);
                    await setGot(gotMap.size);
                    const res2 = await getCompositeSessionsByStreamName(data.sessionId, token);

                    setComposite(res2);
                }
            }
        }
        setRecordLoading(false);
        loadedMap.set(data.sessionId, true);
        await setLoaded(loadedMap.size);
    };

    const checkCompleted = async () => {
        const end = new Date(data.updatedAt);
        const now = new Date(Date.now());

        const diff = now - end;
        const seconds = Math.floor(diff / 1e3);

        await setElasped(seconds);
    };

    const checkS3File = async () => {
        try {
            const sessionId = data.sessionId;
            //const response = await Storage.get(, { download: true, validateObjectExistence: true });
            Storage.get("telemetry/" + sessionId + ".json", { contentType: "application/JSON", contentDisposition: "attachment" })
                .then((data) => {

                    setTelemetry(data);
                    setFromS3(true);
                    setLoading(false);
                    setToggle(true);
                })
                .catch((error) => {
                    console.log(error);
                });

            Storage.get("telemetry/" + sessionId + ".csv")
                .then((data) => {
                    setCsvUrl(data);
                    setFromS3(true);
                    setLoading(false);
                    setToggle(true);
                })
                .catch((error) => {
                    console.log(error);
                });

            Storage.get("telemetry/" + sessionId + ".json", { download: true }).then((data) => {
                setGotJson(true);
            }).catch((error) => {
                console.log("")
            });

            Storage.get("telemetry/" + sessionId + ".csv", { download: true }).then((data) => {
                setGotCsv(true);
            }).catch((error) => {
                console.log("")
            });
        } catch (error) {
            console.log("S3 - ", error);
            setLoading(false);
        }
    };

    async function getTelemetry() {
        const telemetryData = await getTelemetryURL(data.sessionId, token);
        console.log(telemetryData);
        setTelemetry(telemetryData);
        setFromS3(true);
        setLoading(false);
        setToggle(true);
        setGotJson(true);
        setGotCsv(true);
    }

    useEffect(() => {
        checkCompleted();
        getRecordedSessions();
        getTelemetry();
    }, []);

    return (
        <>
            {recordLoading && (
                <div className={styles.session}>
                    <p style={{ height: "9vh", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>Loading Video & Telemetry ...</p>
                </div>
            )}
            {!recordLoading && (
                <>
                    {!!recorded && !islive && (
                        <div className={styles.session}>
                            <div
                                className={styles.close}
                                onClick={(e) => {
                                    e.preventDefault();
                                    // eslint-disable-next-line no-restricted-globals
                                    const confirmation = confirm("Do you want to delete the session ?");

                                    if (confirmation) {
                                        deleteSessions(userDetails.uid, data.sessionId);
                                        Storage.remove("telemetry/" + data.sessionId + ".json");
                                        Storage.remove("telemetry/" + data.sessionId + ".csv");
                                        deleteMilicastVideo(recorded.id, token);
                                        if (!!composite) {
                                            deleteMilicastVideo(composite.id, token);
                                        }
                                        setRecorded(null);
                                    }
                                }}
                            >
                                <AiOutlineCloseCircle className={styles.icon} />
                            </div>
                            <img src={!!recorded && Object.keys(recorded).includes("metadata") && Object.keys(recorded.metadata).includes("thumbnails") ? (recorded.metadata.thumbnails.length > 0 ? recorded.metadata.thumbnails[0] : require("../../assets/img/MOS_logo_v0008.png")) : require("../../assets/img/MOS_logo_v0008.png")} alt="" />
                            <div className={styles.allDetails}>
                                <div className={styles.sessionDetails}>
                                    <h2>MOS Session {length - id}</h2>
                                    <p>{data.sessionId}</p>
                                </div>
                                <div className={styles.timeDetails}>
                                    <font>Started : {new Date(data.createdAt).toLocaleString()}</font>
                                    <font>Ended : {new Date(data.updatedAt).toLocaleString()}</font>
                                </div>
                                <div className={styles.allActionsMobile}>
                                    <div className={styles.islive}>
                                        {!data.status && (
                                            <>
                                                <GiAerialSignal style={{ color: "#888", fontSize: "1.5rem", margin: "5px" }} /> <span> Completed </span>
                                            </>
                                        )}
                                        {data.status && (
                                            <>
                                                <GiAerialSignal style={{ color: "#00FEFB", fontSize: "1.5rem", margin: "5px" }} /> <span>Ongoing</span>
                                            </>
                                        )}
                                    </div>

                                    <div
                                        className={styles.action}
                                        style={{ borderColor: composite ? "#00FEFB" : "#888" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setCompositeDisplay(true);
                                        }}
                                    >
                                        {!composite && (
                                            <>
                                                <img src={require("../../assets/img/MOS_logo_v0008.png")} alt="" style={{ backgroundColor: "rgba(0,0,0,0)", height: "5vh", width: "5vh", filter: "grayscale(100%)" }} />
                                            </>
                                        )}
                                        {!!composite && (
                                            <>
                                                <img src={require("../../assets/img/MOS_logo_v0008.png")} alt="" style={{ backgroundColor: "rgba(0,0,0,0.25)", borderRadius: 100, height: "5vh", width: "5vh" }} />
                                                {compositeDisplay && <VideoPlayer url={composite.download.downloadUrl} poster={composite.metadata.thumbnails[1]} setDisplay={setCompositeDisplay} display={compositeDisplay} />}
                                            </>
                                        )}
                                        <p>View</p>
                                    </div>
                                    <div
                                        className={styles.action}
                                        style={{ borderColor: recorded ? "#00FEFB" : "#888" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setDisplay(true);
                                        }}
                                    >
                                        {!recorded && (
                                            <>
                                                <FaPlay style={{ color: "#888", fontSize: "1.5rem" }} />
                                            </>
                                        )}
                                        {!!recorded && (
                                            <>
                                                <FaPlay style={{ color: "#00FEFB", fontSize: "1.5rem" }} />
                                                {display && <VideoPlayer url={recorded.download.downloadUrl} poster={recorded.metadata.thumbnails[1]} setDisplay={setDisplay} display={display} />}
                                            </>
                                        )}
                                        <p>Play</p>
                                    </div>

                                </div>
                            </div>
                            <div className={styles.allActions}>
                                <div className={styles.islive}>
                                    {!data.status && (
                                        <>
                                            <GiAerialSignal style={{ color: "#888", fontSize: "1.5rem", margin: "5px" }} /> <span> Completed </span>
                                        </>
                                    )}
                                    {data.status && (
                                        <>
                                            <GiAerialSignal style={{ color: "#00FEFB", fontSize: "1.5rem", margin: "5px" }} /> <span>Ongoing</span>
                                        </>
                                    )}
                                </div>

                                <div
                                    className={styles.action}
                                    style={{ borderColor: composite ? "#00FEFB" : "#888" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setCompositeDisplay(true);
                                    }}
                                >
                                    {!composite && (
                                        <>
                                            <img src={require("../../assets/img/MOS_logo_v0008.png")} alt="" style={{ backgroundColor: "rgba(0,0,0,0)", height: "2rem", width: "2rem", marginBottom: 5, filter: "grayscale(100%)" }} />
                                        </>
                                    )}
                                    {!!composite && (
                                        <>
                                            <img src={require("../../assets/img/MOS_logo_v0008.png")} alt="" style={{ backgroundColor: "rgba(0,0,0,0.25)", borderRadius: 100, height: "2rem", width: "2rem", marginBottom: 5 }} />
                                            {compositeDisplay && <VideoPlayer url={composite.download.downloadUrl} poster={composite.metadata.thumbnails[1]} setDisplay={setCompositeDisplay} display={compositeDisplay} />}
                                        </>
                                    )}
                                    <p>View</p>
                                </div>

                                <div
                                    className={styles.action}
                                    style={{ borderColor: gotCsv && gotJson && !!telemetry?.s3_url ? "#00FEFB" : "#888" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setDdownToggle(!ddownToggle);
                                    }}
                                >
                                    {(!recorded || !(gotCsv && gotJson && !!telemetry?.s3_url)) && (
                                        <>
                                            <BsFileEarmarkBarGraphFill style={{ color: "#888", fontSize: "1.5rem", marginBottom: 10, marginTop: 5 }} />
                                        </>
                                    )}
                                    {!!recorded && gotCsv && gotJson && !!telemetry?.s3_url && (
                                        <>
                                            <BsFileEarmarkBarGraphFill style={{ color: "#00FEFB", fontSize: "1.5rem", marginBottom: 10, marginTop: 5 }} />
                                            {ddownToggle && <Dropdown telemetry={!!telemetry?.s3_url ? telemetry?.s3_url : null} />}
                                        </>
                                    )}
                                    <p>Telemetry</p>
                                </div>
                                <div
                                    className={styles.action}
                                    style={{ borderColor: recorded ? "#00FEFB" : "#888" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setDisplay(true);
                                    }}
                                >
                                    {!recorded && (
                                        <>
                                            <FaPlay style={{ color: "#888", fontSize: "1.5rem", marginBottom: 10, marginTop: 5 }} />
                                        </>
                                    )}
                                    {!!recorded && (
                                        <>
                                            <FaPlay style={{ color: "#00FEFB", fontSize: "1.5rem", marginBottom: 10, marginTop: 5 }} />
                                            {display && <VideoPlayer url={recorded.download.downloadUrl} poster={recorded.metadata.thumbnails[1]} setDisplay={setDisplay} display={display} />}
                                        </>
                                    )}
                                    <p>Play</p>
                                </div>
                                {/* <div
                                    className={styles.action}
                                    style={{ borderColor: recorded ? "#00FEFB" : "#888" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        // eslint-disable-next-line no-restricted-globals
                                        const confirmation = confirm("Do you want to delete the session ?");
                                        console.log(confirmation, data.sessionId);
                                        if (confirmation) {
                                            deleteSessions(userDetails.uid, data.sessionId);
                                            Storage.remove("telemetry/" + data.sessionId + ".json");
                                            Storage.remove("telemetry/" + data.sessionId + ".csv");
                                            deleteMilicastVideo(recorded.id);
                                            if (!!composite) {
                                                deleteMilicastVideo(composite.id);
                                            }
                                            setRecorded(null);
                                        }
                                    }}
                                >
                                    {!recorded && (
                                        <>
                                            <AiFillDelete style={{ color: "#888", fontSize: "1.5rem", marginBottom: 10, marginTop: 5 }} />
                                        </>
                                    )}
                                    {!!recorded && (
                                        <>
                                            <AiFillDelete style={{ color: "#00FEFB", fontSize: "1.5rem", marginBottom: 10, marginTop: 5 }} />
                                        </>
                                    )}
                                    <p>Remove</p>
                                </div> */}
                            </div>
                        </div>
                    )}
                    {!recorded && islive && (
                        <div className={styles.session}>
                            <img src={!!recorded && Object.keys(recorded).includes("metadata") && Object.keys(recorded.metadata).includes("thumbnails") ? (recorded.metadata.thumbnails.length > 0 ? recorded.metadata.thumbnails[0] : require("../../assets/img/MOS_logo_v0008.png")) : require("../../assets/img/MOS_logo_v0008.png")} alt="" />
                            <div className={styles.allDetails}>
                                <div className={styles.sessionDetails}>
                                    <h2>MOS Session {length - id}</h2>
                                </div>
                                <div className={styles.timeDetails}>
                                    <font>Started : {new Date(data.createdAt).toLocaleString()}</font>
                                </div>
                                <div className={styles.allActionsMobile}>
                                    <div className={styles.islive}>
                                        {!data.status && (
                                            <>
                                                <GiAerialSignal style={{ color: "#888", fontSize: "1.5rem", margin: "5px" }} /> <span> Completed </span>
                                            </>
                                        )}
                                        {data.status && (
                                            <>
                                                <GiAerialSignal style={{ color: "#00FEFB", fontSize: "1.5rem", margin: "5px" }} /> <span>Ongoing</span>
                                            </>
                                        )}
                                    </div>

                                    <div
                                        className={styles.action}
                                        style={{ borderColor: data.useRemoteExpert ? "#00FEFB" : "#888" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setDisplay(true);
                                        }}
                                    >
                                        {!recorded && (
                                            <>
                                                <FaPlay style={{ color: "#888", fontSize: "1.5rem", margin: "5px" }} />
                                            </>
                                        )}
                                        {!!recorded && (
                                            <>
                                                <FaPlay style={{ color: "#00FEFB", fontSize: "1.5rem", margin: "5px" }} />
                                                {display && <VideoPlayer url={recorded.download.downloadUrl} poster={recorded.metadata.thumbnails[1]} setDisplay={setDisplay} display={display} />}
                                            </>
                                        )}
                                        <p>Play</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.allActions}>
                                <div className={styles.islive}>
                                    {!data.status && (
                                        <>
                                            <GiAerialSignal style={{ color: "#888", fontSize: "1.5rem", margin: "5px" }} /> <span> Completed </span>
                                        </>
                                    )}
                                    {data.status && (
                                        <>
                                            <GiAerialSignal style={{ color: "#00FEFB", fontSize: "1.5rem", margin: "5px" }} /> <span>Ongoing</span>
                                        </>
                                    )}
                                </div>

                                <div className={styles.action} style={{ opacity: 0 }}></div>
                                <CopyToClipboard
                                    className={styles.action}
                                    text={`${window.location.hostname}/live/${data.sessionId}`}
                                    style={{ borderColor: "#00FEFB" }}
                                    onCopy={(e) => {
                                        setCopied(true);
                                    }}
                                >
                                    <span>
                                        <FaShare style={{ color: "#00FEFB", fontSize: "1.5rem", marginBottom: 10, marginTop: 5 }} />

                                        <p>{copied ? "Copied!" : "Share"}</p>
                                    </span>
                                </CopyToClipboard>

                                <a
                                    className={styles.action}
                                    style={{ borderColor: "#00FEFB" }}
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     navigate(`/live/${data.sessionId}`)
                                    // }}
                                    href={`/live/${data.sessionId}`}
                                >
                                    <img src={require("../../assets/img/MOS_logo_v0008.png")} alt="" style={{ backgroundColor: "rgba(0,0,0,0.25)", borderRadius: 100, height: "5vh", width: "5vh", marginBottom: 10 }} />
                                    <p>View</p>
                                </a>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

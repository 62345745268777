import React from "react";
import styles from "./home.module.css";
import Header from "../../components/header";
import Button from "../../components/button";
import Tooltip from "../../components/tooltip";
import BackVideo from "../../components/backvideo";
import Moon from "../../components/moon";
import { useNavigate } from "react-router-dom";
import { FaArrowDownLong } from "react-icons/fa6";

export default function Home(props) {
    const navigate = useNavigate();

    return (
        <>
            <BackVideo videos={["https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.mp4", "https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.webm"]} poster={"https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-poster-00001.jpg"} />
            <div className={styles.home}>
                <Moon />
                <Header content={"skypad"} space={"6vh"} />
                <div className={styles.homecontent}>
                    <div className={styles.dummy}></div>
                    <Button
                        content={"START"}
                        className={styles.button}
                        onClick={(e) => {
                            navigate("/start");
                        }}
                    />
                    <div className={styles.tooltipContainer}>
                        <div>
                            <Tooltip
                                className={styles.tooltip}
                                content={"TAP THE CONTROLLER"}
                            />
                        </div>
                        <div className={styles.tooltipIconContainer}>
                            <FaArrowDownLong className={styles.icons} />
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}

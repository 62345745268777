import styles from "./styles.module.css"

import React from 'react'

const Info = (props) => {
  const{content, className} = props;

  return (
    <button className={[styles.button].concat(className).join(" ")}><span style={{zIndex: 10, marginTop: -2.5}}>{content}</span></button>
  )
}

export default Info
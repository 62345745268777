import React from "react";
import styles from "./styles.module.css";
import Header from "../../components/header";
import BackVideo from "../../components/backvideo";
import { useParams } from "react-router-dom";

export default function Live() {
    let { sid } = useParams();

    return (
        <>
            <BackVideo videos={["https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.mp4", "https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.webm"]} poster={"https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-poster-00001.jpg"} />
            <div className={styles.home}>
                <Header content={"skypad"} space={"6vh"} />
                <div className={styles.homecontent}>
                    <iframe title="Stream" src={ !!sid ? `https://viewer.millicast.com/?streamId=RPWd9W/skypad.${sid}.composite&token=d8f8d78cf7882e3a5468d6d2d7cdbf6e6e34da96407a4fb32452d8cb956cc412&volume=false&pip=false&cast=false&disableSettings=true` : "https://viewer.millicast.com?streamId=RPWd9W/demo.composite&volume=false&pip=false&cast=false&disableSettings=true"} allowFullScreen={true} className={styles.stream} id="stream"></iframe>
                </div>
            </div>
        </>
    );
}

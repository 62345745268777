import React from "react";
import styles from "./styles.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function DialogBoxGF(props) {
    const { setShow, title, src } = props;
    return (
        <div className={styles.overlay}>
            <div className={styles.dialogGFContainer}>
                <div
                    className={styles.close}
                    onClick={(e) => {
                        setShow(false);
                    }}
                >
                    <AiOutlineCloseCircle className={styles.icon} />
                </div>
                <font className={styles.heading}>{title}</font>
                <iframe src={src} title="form" className={styles.form} frameborder="0" marginheight="0" marginwidth="0">
                    Loading…
                </iframe>
            </div>
        </div>
    );
}
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export async function getSessionsByStreamName(id, token) {
    try {
        const url = `${process.env.REACT_APP_MILICAST_URL}?q=${id + ".plate"}`;
        const res = await axios.get(url, {
            headers:{Authorization: "Bearer "+token}
        });
        if (res.status === 200 && res.data) {
            return res.data;
        } else {
            const url2 = `${process.env.REACT_APP_MILICAST_URL}?q=${id}`;
            const res2 = await axios.get(url2);
            if (res2.status === 200) {
                return res2.data;
            } else {
                console.log(res.status, res.statusText, res.data);
                return null;
            }
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getCompositeSessionsByStreamName(id, token) {
    try {
        const url = `${process.env.REACT_APP_MILICAST_URL}?q=${id + ".composite"}`;
        const res = await axios.get(url, {
            headers:{Authorization: "Bearer "+token}
        });
        if (res.status === 200) {
            return res.data;
        } else {
            console.log(res.status, res.statusText, res.data);
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function deleteMilicastVideo(id, token) {
    try {
        const url = `${process.env.REACT_APP_MILICAST_URL}?q=${id}`;
        const res = await axios.delete(url, {
            headers:{Authorization: "Bearer "+token}
        });
        if (res.status === 200) {
            return res.data;
        } else {
            console.log(res);
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getAllSessions(uid, token) {
    try {
            const res = await axios.get(process.env.REACT_APP_SESSION_URL + `session/` + uid , {
                headers:{Authorization: "Bearer "+token}
            });

        return res.data
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getOrganizations(uid, token) {
    try {
        let result = [];
        let hostId = "";
        let sessionId = "";
        while (true) {
            const res = await axios.get(process.env.REACT_APP_ORGANIZATION_URL + `organization/` + uid + hostId + sessionId, {
                headers: { Authorization: "Bearer " + token }
            });
            result = result.concat(res.data.Items);
            if (!!res.data["LastEvaluatedKey"]) {
                hostId = "/" + res.data["LastEvaluatedKey"].hostId;
                sessionId = "/" + res.data["LastEvaluatedKey"].sessionId;
            } else {
                break;
            }
        }

        return result;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getVehicleTypes( token) {
    try {
        
            const res = await axios.get(process.env.REACT_APP_VEHICLES_TYPES_URL + `vehicletype/`, {
                headers: { Authorization: "Bearer " + token }
            });

        return res.data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getUserVehicle(userId, token) {
    try {
        
            const res = await axios.get(process.env.REACT_APP_VEHICLES_URL + `vehicle/` + userId, {
                headers: { Authorization: "Bearer " + token }
            });

        return res.data;
    } catch (e) {
        console.log(e);
        return null;
    }
}


export async function addUserVehicle(name, userId, vehicleId, token) {
    try {
        const url = process.env.REACT_APP_VEHICLES_URL;
        const id = uuidv4();
        const res = await axios.post(url + `vehicle/`, {
            ownerId: userId,
            id: id,
            createdAt: (new Date()).toISOString(),
            name: name,
            vehicleTypeId: vehicleId
        }, {
            headers: { Authorization: "Bearer " + token }
        });
        return res
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function deleteUserVehicle(token, id, userId) {
    try {
            const res = await axios.delete(process.env.REACT_APP_VEHICLES_URL + `vehicle/object/${encodeURIComponent(userId)}/${encodeURIComponent(id)}` , {
                headers: { Authorization: "Bearer " + token }
            });

        return res.data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getOrganizationDetails(organizationId, token) {
    try {
            const res = await axios.get(process.env.REACT_APP_ORGANIZATION_URL + `organization/object/` + organizationId, {
                headers: { Authorization: "Bearer " + token }
            });
            console.log(res);
            return res.data
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getUserOrganizations(uid, token) {
    try {
        const res = await axios.get(process.env.REACT_APP_ORGANIZATION_RELATION_URL + `organizationrelation/` + uid, {
            headers: { Authorization: "Bearer " + token }
        });
        console.log("User Organization",res);
        return res.data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function addOrganization(name, logo, token) {
    try {
        const url = process.env.REACT_APP_ORGANIZATION_URL;
        const id = uuidv4();
        const res = await axios.post(url + `organization/`, {
            organizationId: id,
            organizationName: name,
            logo: logo
        }, {
            headers: { Authorization: "Bearer " + token }
        });
        return res
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function deleteSessions(uid, sid) {
    try {
        const res = await axios.delete(process.env.REACT_APP_SESSION_URL + `session/object/${encodeURIComponent(uid)}/${encodeURIComponent(sid)}`);
        return res;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getAllForms() {
    try {
        const url = process.env.REACT_APP_FORM_URL;
        const res = await axios.get(url + `forms/id`);
        if (res.status === 200) {
            return res.data;
        } else {
            console.log(res.status, res.statusText, res.data);
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getSettings(q) {
    try {
        const url = process.env.REACT_APP_SETTINGS_URL;
        const res = await axios.get(url + `settings` + (q ? `?q=${q}` : ""));
        if (res.status === 200) {
            return res.data.data;
        } else {
            console.log(res.data);
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function uploadForms(data) {
    try {
        const url = process.env.REACT_APP_FORM_URL;
        const body = JSON.stringify(data);
        const id = uuidv4();
        const ts = Date.now();
        const res = await axios.post(url + `forms/`, {
            id: id,
            timestamp: ts.toString(),
            data: body,
            read: false,
        });
        return res
    } catch (e) {
        console.log(e);
        return null;
    }
}
export async function editSession(obj, token) {
    try {
        obj["status"] = false;
        const res = await axios.post(`${process.env.REACT_APP_SESSION_URL}session`, obj, { headers: { Authorization: "Bearer " + token } });
        if (res.status === 200) {
            return res.data;
        } else {
            console.log(res.status, res.statusText, res.data.status);
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function liveSession(token) {
    try {
        let result = [];
        let hostId = "";
        let sessionId = "";
        while (true) {
            const res = await axios.get(`${process.env.REACT_APP_SESSION_URL}session/live/list` + hostId + sessionId, { headers: { Authorization: "Bearer " + token } });
            result = result.concat(res.data.Items);
            if (!!res.data["LastEvaluatedKey"]) {
                hostId = "/" + res.data["LastEvaluatedKey"].hostId;
                sessionId = "/" + res.data["LastEvaluatedKey"].sessionId;
            } else {
                break;
            }
        }
        return result;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getTelemetryURL(sessionId, token) {
    try {
            const res = await axios.get(process.env.REACT_APP_GRID_URL + `session?session=` + encodeURIComponent(sessionId), {
                headers: { 
                    Authorization: "Bearer " + token,
                }
            });

        return res.data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Controller from "../../components/controller";
import Header from "../../components/header";
import BackVideo from "../../components/backvideo";
import Button from "../../components/button";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAuth } from "../../contexts/AmplifyAuthContext";
import Profile from "../../components/profile";
import { useNavigate, useParams } from "react-router-dom";
import { GiAerialSignal } from "react-icons/gi";
import { IoIosVideocam } from "react-icons/io";
import { FaPlay } from "react-icons/fa";
import { RiVideoDownloadFill } from "react-icons/ri";
import { getUserSessions } from "../../aws";
import { getAllSessions, getSessionsByStreamName, uploadForms } from "../../api";
import api from "../../timestream";
import { API, Storage } from "aws-amplify";

function isoToTs(iso) {
    return new Date(iso).getTime();
}

export default function Grid(props) {
    const { userDetails } = useAuth();
    const { to, from } = useParams();
    console.log(to, from);
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState([]);
    const [count, setCount] = useState(0);
    const [recorded, setRecorded] = useState({});
    const [display, setDisplay] = useState();

    const navigate = useNavigate();
    // eslint-disable-next-line
    const [name, setName] = useState(userDetails ? userDetails.firstname : "");

    const NameComponent = () => (
        <>
            Hello <span>{name}</span>! WHAT ISSUES ARE YOU FACING?
        </>
    );
    console.log(userDetails);

    function compare(a, b) {
        const tsTo = new Date(to);
        const tsFrom = new Date(from);
        const tsSessionA = new Date(a.createdAt);
        const tsSessionB = new Date(a.createdAt);
        if (to && from) {
            if ((tsSessionA > tsFrom && tsSessionA < tsTo) || (tsSessionB > tsFrom && tsSessionB < tsTo)) {
                setCount(count + 1);
            }
        } else {
            setCount(count + 1);
        }

        if (new Date(a.createdAt) < new Date(b.createdAt)) {
            return -1;
        }
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
            return 1;
        }
        return 0;
    }

    async function getRecordedFiles(data) {
        for (let i = 0; i < data.length; i++) {
            const item = data[i];

            if (to && from) {
                const tsTo = new Date(to);
                const tsFrom = new Date(from);
                const tsSessionA = new Date(item.createdAt);
                if (tsSessionA > tsFrom && tsSessionA < tsTo) {
                    const res = await getSessionsByStreamName(item.sessionId);
                    console.log(res);
                    if (res) {
                        let temp = recorded;
                        temp[item.sessionId] = res;
                        setRecorded(temp);
                    }
                }
            } else {
                const res = await getSessionsByStreamName(item.sessionId);
                console.log(res);
                if (res) {
                    let temp = recorded;
                    temp[item.sessionId] = res;
                    setRecorded(temp);
                }
            }
        }
    }

    useEffect(() => {
        if (userDetails && !userDetails.customer) {
            navigate("/journey");
        }

        // getUserSessions(userDetails.uid)
        //   .then((res) => {
        //     console.log(res)
        //     setSessions(res.data)
        //   })
        //   .catch((err) => {
        //     console.log(err)
        //   })
        //   .finally(() => {
        //     setLoading(false)
        //   })

        getAllSessions(userDetails.uid)
            .then(async (res) => {
                let data = res.sort(compare);
                console.log("test 1 ", data);
                console.log("test 2 ", res.reverse());

                if (to && from) {
                    const tsTo = new Date(to);
                    const tsFrom = new Date(from);
                    let temp = [];
                    for (let i = 0; i < data.length; i++) {
                        const item = data[i];
                        const tsSessionA = new Date(item.createdAt);
                        if (tsSessionA > tsFrom && tsSessionA < tsTo) {
                            temp.push(item);
                        }
                    }

                    setSessions(temp);
                    setDisplay(temp.slice(0, 10));
                } else {
                    setSessions(data);
                    setDisplay(data.slice(0, 10));
                }

                setLoading(false);
                //await getRecordedFiles(data)
            })
            .catch((err) => {
                console.log(err);
            });

        console.log(userDetails);
    }, [userDetails]);

    return (
        <>
            <BackVideo videos={["https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.mp4", "https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.webm"]} poster={"https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-poster-00001.jpg"} />

            <div className={styles.home}>
                <div
                    className={styles.backButton}
                    onClick={(e) => {
                        navigate("/dashboard");
                    }}
                >
                    {"< BACK"}
                </div>
                <Header content={"GRID"} space={"6vh"} />
                {/* <CollapseMenu navigate={navigate} setShow={setShow} setSelect={setSelect} /> */}

                <div className={styles.mosList}>
                    {!!userDetails && sessions.length > 0 && count > 0 ? (
                        <>
                            {!loading && (
                                <>
                                    {display.map((session, key) => {
                                        const data = session;
                                        if (to && from) {
                                            const tsTo = new Date(to);
                                            const tsFrom = new Date(from);
                                            const tsSession = new Date(session.createdAt);
                                            if (tsSession > tsFrom && tsSession < tsTo) {
                                                return <SessionItem data={data} id={key} length={sessions.length} />;
                                            } else {
                                                return <></>;
                                            }
                                        } else {
                                            return <SessionItem data={data} id={key} length={sessions.length} />;
                                        }
                                    })}
                                    {display.length < sessions.length && (
                                        <div>
                                            <Button
                                                onClick={(e) => {
                                                    const diff = sessions.length - display.length;
                                                    if (diff > 10) {
                                                        setDisplay(sessions.slice(0, display.length + 10));
                                                    } else {
                                                        setDisplay(sessions);
                                                    }
                                                }}
                                                content={"Load More"}
                                                className={styles.loadButton}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            {loading && <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontWeight: 300 }}>{"Loading Recordings.."}</div>}
                        </>
                    ) : (
                        <>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontWeight: 300 }}>{!loading ? (count > 0 ? "Not enough MOS adventures yet." : `No MOS session found from ${from} to ${to}`) : "Loading.."}</div>
                        </>
                    )}
                    <div style={{ marginTop: "2vw" }}></div>
                </div>
            </div>
        </>
    );
}

function SessionItem(props) {
    const { userDetails } = useAuth();
    const [display, setDisplay] = useState(false);
    const [recorded, setRecorded] = useState(null);
    const [elasped, setElasped] = useState(0);
    const [telemetryData, setTelemetryData] = useState({ telemetry: [] });
    const [tempData, setTempData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [toggle, setToggle] = useState(false);
    const { data, id, length } = props;
    const [fileUrl, setFileUrl] = useState();
    const [fromS3, setFromS3] = useState(false);
    const [telemetry, setTelemetry] = useState();
    const [csvUrl, setCsvUrl] = useState();
    const [csv, setCsv] = useState();

    const getRecordedSessions = async () => {
        const res = await getSessionsByStreamName(data.sessionId);
        console.log(res.metadata.thumbnails);
        setRecorded(res);
    };

    const checkCompleted = () => {
        const end = new Date(data.updatedAt);
        const now = new Date(Date.now());

        const diff = now - end;
        const seconds = Math.floor(diff / 1e3);
        console.log(seconds);
        setElasped(seconds);
    };

    const checkS3File = async () => {
        try {
            console.log("trying S3");
            const sessionId = data.sessionId;
            //const response = await Storage.get(, { download: true, validateObjectExistence: true });
            Storage.get("telemetry/" + sessionId + ".json", { contentType: "application/JSON", contentDisposition: "attachment" })
                .then((data) => {
                    console.log(data);
                    setTelemetry(data);
                    setFromS3(true);
                    setLoading(false);
                    setToggle(true);
                })
                .catch((error) => {
                    console.log(error);
                });

            Storage.get("telemetry/" + sessionId + ".csv")
                .then((data) => {
                    setCsvUrl(data);
                    setFromS3(true);
                    setLoading(false);
                    setToggle(true);
                })
                .catch((error) => {
                    console.log(error);
                });

            // console.log("This is a test : ", sessionId, response);
            // // data.Body is a Blob
            // response.Body.text().then((string) => {
            //     if (!!string) {
            //         const data = JSON.parse(string);
            //         const tempString = Array.isArray(data)? { telemetry: data } : data;
            //         console.log(tempString);
            //         setTelemetryData(tempString);
            //         generateCSV(tempString);

            //     }
            //     setToggle(true);
            //     setFileUrl(response);
            //     setLoading(false);
            //     setFromS3(true)
            // });
        } catch (error) {
            console.log();
            setLoading(false);
        }
    };

    async function queryTSDB(uid, start, end, name) {
        try {
            await setLoading(true);
            console.log("trying");
            await api.setConfiguration("us-east-1", "", "");
            const res = await api.rawQuery(`
            SELECT measure_name, measure_value::bigint, measure_value::double, measure_value::varchar, measure_value::boolean, time AS binned_timestamp
            FROM "SKYPAD_DB_PROD"."skypad_db_table_prod"
            WHERE node='${uid}' 
                AND time < from_iso8601_timestamp('${end}')
                AND time > from_iso8601_timestamp('${start}')
            ORDER BY time ASC
            LIMIT 8000`);

            console.log("Got", res.Rows.length);
            if (res.Rows.length > 0) {
                console.log(uid, start, end, name, res.Rows);
                let telemetry = tempData;
                let ct = res.Rows[0].Data[5].ScalarValue;
                let ts = isoToTs(ct.replace(" ", "T").slice(0, -6) + "Z");

                let metrics = [];
                let valueTypes = ["Int64", "Float", "String", "Bool"];
                for (let i = 0; i < res.Rows.length; i++) {
                    const item = res.Rows[i];
                    let obj = {
                        name: item.Data[0].ScalarValue,
                    };
                    for (let j = 1; j <= 4; j++) {
                        if (!item.Data[j].NullValue) {
                            obj["datatype"] = valueTypes[j - 1];
                            switch (j) {
                                case 1:
                                    obj["value"] = parseInt(item.Data[j].ScalarValue);
                                    break;

                                case 2:
                                    obj["value"] = parseFloat(item.Data[j].ScalarValue);
                                    break;

                                case 3:
                                    obj["value"] = item.Data[j].ScalarValue;
                                    break;

                                case 4:
                                    obj["value"] = item.Data[j].ScalarValue.toLowerCase() === "true";
                                    break;

                                default:
                                    break;
                            }
                            break;
                        }
                    }
                    if (item.Data[5].ScalarValue === ct) {
                        obj["timestamp"] = ts;
                        metrics.push(obj);
                    } else {
                        let telemetryObj = {
                            timestamp: ts,
                            metrics: metrics,
                        };

                        telemetry.push(telemetryObj);

                        ct = item.Data[5].ScalarValue;
                        ts = isoToTs(ct.replace(" ", "T").slice(0, -6) + "Z");
                        metrics = [];
                        obj["timestamp"] = ts;
                        metrics.push(obj);
                    }
                }

                console.log(telemetry.length, Math.abs(ts - isoToTs(end)), ts);
                setTempData(telemetry);
                console.log();
                if (res.Rows.length === 8000) {
                    console.log("Going Again", start, ct.replace(" ", "T").slice(0, -6) + "Z");
                    queryTSDB(userDetails.uid, ct.replace(" ", "T").slice(0, -6) + "Z", new Date(data.updatedAt).toISOString(), `MOS Session ${length - id}`);
                } else {
                    setLoading(false);
                    setToggle(true);
                    setTelemetryData({ telemetry: telemetry });
                }
            } else {
                console.log("None");
                setLoading(false);
            }

            // if(!!res.Rows.NextToken){
            //   queryTSDB(uid, start, end, name, offset+1, res.Rows.NextToken)
            // }
        } catch (e) {
            console.log(e);
        }
    }

    function generateCSV(data) {
        console.log("CSV", data);
        try {
            let headers = ["timestamp"];
            let metricsArr = [];

            const telemetryArray = data.telemetry;

            for (let i = 0; i < telemetryArray.length; i++) {
                if (Object.keys(telemetryArray[i]).length > 0) {
                    const metrics = telemetryArray[i].metrics;
                    for (let j = 0; j < metrics.length; j++) {
                        const element = metrics[j];
                        let headerPos = headers.indexOf(element.name);
                        if (headerPos === -1) {
                            headers.push(element.name);
                        }
                    }
                }
            }

            for (let i = 0; i < telemetryArray.length; i++) {
                if (Object.keys(telemetryArray[i]).length > 0) {
                    const metrics = telemetryArray[i].metrics;
                    let row = [];
                    for (let j = 0; j < headers.length; j++) {
                        row.push(undefined);
                    }
                    row[0] = telemetryArray[i].timestamp;
                    for (let j = 0; j < metrics.length; j++) {
                        const element = metrics[j];
                        let headerPos = headers.indexOf(element.name);
                        if (headerPos !== -1) {
                            row[headerPos] = element.value;
                        }
                    }
                    metricsArr.push(row.join(","));
                }
            }

            console.log(headers);
            console.log(metricsArr);

            let csvString = headers.join(",") + "\n" + metricsArr.join("\n");
            console.log(csvString);
            if (telemetryArray.length > 0) {
                setCsv(csvString);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        checkCompleted();
        checkS3File();
        //queryTSDB(userDetails.uid, new Date(data.createdAt).toISOString(), new Date(data.updatedAt).toISOString(), `MOS Session ${length - id}`, 0);
    }, []);

    useEffect(() => {
        generateCSV(telemetryData);
    }, [telemetryData]);

    useEffect(() => {
        console.log("Logging :", `MOS Session ${length - id}`, toggle, telemetryData, fromS3, loading);
    }, [toggle, telemetryData, fromS3, loading]);

    return (
        <div className={styles.session}>
            <img src={!!recorded ? (recorded.metadata.thumbnails.length > 0 ? recorded.metadata.thumbnails[0] : require("../../assets/img/MOS_logo_v0008.png")) : require("../../assets/img/MOS_logo_v0008.png")} alt="" />
            <div className={styles.allDetails}>
                <div className={styles.sessionDetails}>
                    <h2>MOS Session {length - id}</h2>
                    <p>{data.sessionId}</p>
                </div>
                <div className={styles.timeDetails}>
                    <font>Started : {new Date(data.createdAt).toLocaleString()}</font>
                    <font>Ended : {new Date(data.updatedAt).toLocaleString()}</font>
                </div>

                {/* <a href={data.useRemoteExpert? "https://skypad-video-bucket-dev.s3.amazonaws.com/" + data.id + ".mp4" : "#"} target="_blank" rel="noreferrer" download={"MOS Session " + (id + 1).toString()}>
          <div className={styles.action} style={{ borderColor: data.useRemoteExpert? "#00FEFB" : "#888" }}>
            {
              !data.useRemoteExpert &&
              <>
                <RiVideoDownloadFill style={{ color: "#888", fontSize: "1.5rem", margin: "1vh" }} />
              </>
            }
            {
              data.useRemoteExpert &&
              <>
                <RiVideoDownloadFill style={{ color: "#00FEFB", fontSize: "1.5rem", margin: "1vh" }} />
              </>
            }
            <p>Download</p>
          </div>
        </a> */}
                <div className={styles.allActionsMobile}>
                    <div className={styles.islive}>
                        {!toggle && (
                            <>
                                {loading && <Button content={"LOADING"} />}
                                {!loading && (
                                    <Button
                                        onClick={(e) => {
                                            setToggle(true);
                                            setLoading(true);
                                            queryTSDB(userDetails.uid, new Date(data.createdAt).toISOString(), new Date(data.updatedAt).toISOString(), `MOS Session ${length - id}`, 0);
                                        }}
                                        content={"INITIATE"}
                                    />
                                )}
                            </>
                        )}

                        {toggle && (
                            <>
                                {!loading && (
                                    <>
                                        {fromS3 && (
                                            <>
                                                <a href={telemetry} download={`mos-session-${data.sessionId}-telemetry.json`} target={"_blank"} rel={"noreferrer"}>
                                                    <Button content={"DOWNLOAD JSON"} onClick={async (e) => {}} />
                                                </a>
                                                {!!csvUrl && (
                                                    <a href={csvUrl} download={`mos-session-${data.sessionId}-telemetry.csv`} target={"_blank"} rel={"noreferrer"}>
                                                        <Button content={"DOWNLOAD CSV"} />
                                                    </a>
                                                )}
                                            </>
                                        )}
                                        {!fromS3 && (
                                            <>
                                                <a href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(telemetryData))}`} download={`mos-session-${data.sessionId}-telemetry.json`}>
                                                    <Button content={"DOWNLOAD JSON"} />
                                                </a>
                                                {!!csv && (
                                                    <a href={`data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`} download={`mos-session-${data.sessionId}-telemetry.csv`}>
                                                        <Button content={"DOWNLOAD CSV"} />
                                                    </a>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                {loading && <Button content={"GENERATING"} />}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.allActions}>
                <div className={styles.islive}>
                    {/* <div
                    className={styles.action}
                    style={{ borderColor: data.useRemoteExpert? "#00FEFB" : "#888" }}
                    onClick={(e) => {
                        e.preventDefault();
                        setDisplay(true);
                    }}
                >
                    {!recorded && (
                        <>
                            <FaPlay style={{ color: "#888", fontSize: "1.5rem", margin: "1vh" }} />
                        </>
                    )}
                    {!!recorded && (
                        <>
                            <FaPlay style={{ color: "#00FEFB", fontSize: "1.5rem", margin: "1vh" }} />
                            {display && <VideoPlayer url={recorded.download.downloadUrl} poster={recorded.metadata.thumbnails[1]} setDisplay={setDisplay} display={display} />}
                        </>
                    )}
                    <p>Play</p>
                </div> */}
                    {!toggle && (
                        <>
                            {loading && <Button content={"LOADING"} />}
                            {!loading && (
                                <Button
                                    onClick={(e) => {
                                        setToggle(true);
                                        setLoading(true);
                                        queryTSDB(userDetails.uid, new Date(data.createdAt).toISOString(), new Date(data.updatedAt).toISOString(), `MOS Session ${length - id}`, 0);
                                    }}
                                    content={"INITIATE"}
                                />
                            )}
                        </>
                    )}

                    {toggle && (
                        <>
                            {!loading && (
                                <>
                                    {fromS3 && (
                                        <>
                                            <a href={telemetry} download={`mos-session-${data.sessionId}-telemetry.json`} target={"_blank"} rel={"noreferrer"}>
                                                <Button content={"DOWNLOAD JSON"} onClick={async (e) => {}} />
                                            </a>
                                            {!!csvUrl && (
                                                <a href={csvUrl} download={`mos-session-${data.sessionId}-telemetry.csv`} target={"_blank"} rel={"noreferrer"}>
                                                    <Button content={"DOWNLOAD CSV"} />
                                                </a>
                                            )}
                                        </>
                                    )}
                                    {!fromS3 && (
                                        <>
                                            <a href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(telemetryData))}`} download={`mos-session-${data.sessionId}-telemetry.json`}>
                                                <Button content={"DOWNLOAD JSON"} />
                                            </a>
                                            {!!csv && (
                                                <a href={`data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`} download={`mos-session-${data.sessionId}-telemetry.csv`}>
                                                    <Button content={"DOWNLOAD CSV"} />
                                                </a>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            {loading && <Button content={"GENERATING"} />}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

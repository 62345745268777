import React from "react";
import styles from "./styles.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function DialogBox(props) {
    const { heading, content, setState, state } = props;

    return (
        <div className={styles.overlay} style={{ display: state ? "flex" : "none" }}>
            <div className={styles.container}>
                <div className={styles.closeContainer}>
                    <AiOutlineCloseCircle
                        className={styles.close}
                        onClick={(e) => {
                            setState(false);
                        }}
                    />
                </div>
                <div className={styles.dialogContainers}>
                    <div className={styles.dialogHeading}>{heading}</div>
                    <div className={styles.dialogContent}>{content}</div>
                </div>
            </div>
        </div>
    );
}
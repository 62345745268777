import React, { useState } from "react";
import styles from "./styles.module.css";
import BackVideo from "../../components/backvideo";
import Button from "../../components/button";

export default function MOSHome(props) {
    const videos = [
        {
            files: [require("../../assets/videos/jetsuitBGBeach6-transcode.mp4"), require("../../assets/videos/jetsuitBGBeach6-transcode.webm")],
            poster: require("../../assets/videos/jetsuitBGBeach6-poster-00001.jpg"),
        },
        {
            files: [require("../../assets/videos/skypad_des_droneHud_EY_v0006_bg-transcode.mp4"), require("../../assets/videos/skypad_des_droneHud_EY_v0006_bg-transcode.webm")],
            poster: require("../../assets/videos/skypad_des_droneHud_EY_v0006_bg-poster-00001.jpg"),
        },
        {
            files: [require("../../assets/videos/skypad_des_motoHud_EY_v0005_bg-transcode.webm")],
            poster: require("../../assets/videos/skypad_des_motoHud_EY_v0005_bg-transcode_1.jpg"),
        },
    ];

    const mos = [
        [require("../../assets/videos/mos/jetsuit.mp4"), require("../../assets/videos/mos/jetsuit.webm")],
        [require("../../assets/videos/mos/drone.mp4"), require("../../assets/videos/mos/drone.webm")],
        [require("../../assets/videos/mos/motorcycle.mp4"), require("../../assets/videos/mos/motorcycle.webm")],
    ];

    const [current, setCurrent] = useState(0);

    return (
        <>
            {current === 0 && <BackVideo videos={videos[0].files} poster={videos[0].poster} otherClass={styles.back} />}
            {current === 1 && <BackVideo videos={videos[1].files} poster={videos[1].poster} otherClass={styles.back} />}
            {current === 2 && <BackVideo videos={videos[2].files} poster={videos[2].poster} otherClass={styles.back} />}

            <div className={styles.home}>
                <div className={styles.alert}>Turn your device landscape for the full experience!</div>
                {current === 0 && (
                    <video playsInline={true} autoPlay={true} muted={true} loop={true} className={styles.HUD}>
                        {mos[0].map((links, index) => {
                            return <source src={links} key={index} />;
                        })}
                    </video>
                )}
                {current === 1 && (
                    <video playsInline={true} autoPlay={true} muted={true} loop={true} className={styles.HUD}>
                        {mos[1].map((links, index) => {
                            return <source src={links} key={index} />;
                        })}
                    </video>
                )}
                {current === 2 && (
                    <video playsInline={true} autoPlay={true} muted={true} loop={true} className={styles.HUD}>
                        {mos[2].map((links, index) => {
                            return <source src={links} key={index} />;
                        })}
                    </video>
                )}

                <div className={styles.options}>
                    <Button
                        onClick={(e) => {
                            setCurrent(0);
                        }}
                        content={"Water"}
                        className={styles.optionContent}
                    />
                    <Button
                        onClick={(e) => {
                            setCurrent(1);
                        }}
                        content={"Air"}
                        className={styles.optionContent}
                    />
                    <Button
                        onClick={(e) => {
                            setCurrent(2);
                        }}
                        content={"Land"}
                        className={styles.optionContent}
                    />
                </div>
            </div>
        </>
    );
}

import {API} from 'aws-amplify';

export async function getUserInfo(user){
    console.log('getUserInfo', user)
    try {
        const res = API.get('users', "/users/object/"+user)
        return res
    } catch (error) {
        return null
    }
}

export async function getAllVersions(){
    try {
        const res = API.get('version', "/version/")
        return res
    } catch (error) {
        return null
    }
}
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Header from "../../components/header";
import BackVideo from "../../components/backvideo";
import { useAuth } from "../../contexts/AmplifyAuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API, Storage } from "aws-amplify";

import DOMPurify from "dompurify"; // Import DOMPurify library

export default function Announcements(props) {
    const { userDetails } = useAuth();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [stage, setStage] = useState(0);
    const [data, setData] = useState(null);
    const [image, setImage] = useState(null);

    const navigate = useNavigate();

    async function getData() {
        try {
            const data = await API.get("announcement", "/announcement/object/" + id);
            const docId = data.link.slice(data.link.lastIndexOf("/d/") + 3, data.link.lastIndexOf("/"));
            setStage(1);
            const imgData = await Storage.get(data.key.key)
            setImage(imgData);
            setStage(2);
            const res = await axios.post(`${process.env.REACT_APP_GDOC_URL}gdoc/` + docId);
            setData(res.data.success.data);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (userDetails && !userDetails.customer) {
            navigate("/journey");
        }

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails]);

    // Sanitize the image state value before using it in the img tag
    const sanitizedImage = DOMPurify.sanitize(image);

    return (
        <>
            <BackVideo videos={["https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.mp4", "https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.webm"]} poster={"https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-poster-00001.jpg"} />

            <div className={styles.home}>
                <div
                    className={styles.backButton}
                    onClick={(e) => {
                        navigate("/journey");
                    }}
                >
                    {"< BACK"}
                </div>
                <Header content={"ANNOUNCEMENT"} space={"3vw"} />
                {/* <CollapseMenu navigate={navigate} setShow={setShow} setSelect={setSelect} /> */}

                <div className={styles.mosList}>
                    {!loading && (
                        <>
                            <div className={styles.imageContainer}>
                                <img src={sanitizedImage} alt="" /> {/* Use the sanitizedImage variable */}
                            </div>
                            <div className={styles.textContainer}>
                                {data.body.content.map((item, index) => {
                                    if (Object.keys(item).includes("paragraph")) {
                                        const paragraph = item.paragraph;
                                        const text = paragraph.elements.filter((e) => Object.keys(e).includes("textRun"))[0].textRun.content;
                                        const textStyle = paragraph.paragraphStyle.namedStyleType;
                                        const allStyles = ["TITLE", "SUBTITLE", "HEADING_1", "HEADING_2", "HEADING_3", "HEADING_4", "NORMAL_TEXT"];
                                        switch (allStyles.indexOf(textStyle)) {
                                            case 0:
                                                return (
                                                    <h1 style={{ marginBottom: 10 }} key={index}>
                                                        {text}
                                                    </h1>
                                                );
                                            case 1:
                                                return (
                                                    <h3 style={{ marginBottom: 10 }} key={index}>
                                                        {text}
                                                    </h3>
                                                );
                                            case 2:
                                                return (
                                                    <h2 style={{ marginBottom: 10 }} key={index}>
                                                        {text}
                                                    </h2>
                                                );
                                            case 3:
                                                return (
                                                    <h4 style={{ marginBottom: 10 }} key={index}>
                                                        {text}
                                                    </h4>
                                                );
                                            case 4:
                                                return (
                                                    <h5 style={{ marginBottom: 10 }} key={index}>
                                                        {text}
                                                    </h5>
                                                );
                                            case 5:
                                                return (
                                                    <h6 style={{ marginBottom: 10 }} key={index}>
                                                        {text}
                                                    </h6>
                                                );
                                            case 6:
                                                return (
                                                    <p style={{ marginBottom: 10 }} key={index}>
                                                        {text}
                                                    </p>
                                                );
                                            default:
                                            // code block
                                        }
                                    }
                                    return <div key={index}></div>;
                                })}
                            </div>
                        </>
                    )}
                    {loading && (
                        <div className={styles.loadingContainer}>
                            <div className="loader"></div>
                            {(() => {
                                switch (stage) {
                                    case 0:
                                        return "Unveiling the Grand Overview...";

                                    case 1:
                                        return "Summoning the Epic Visuals...";

                                    case 2:
                                        return "Illuminating the Narrative...";

                                    default:
                                        break;
                                }
                            })()}
                        </div>
                    )}
                    <div style={{ marginTop: "2vw" }}></div>
                </div>
            </div>
        </>
    );
}

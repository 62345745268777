import styles from "./styles.module.css"

import React from 'react'

const Tooltip = ({content, onClick, className, type, disabled}) => {

  return (
    <button className={[styles.button, !!disabled ? styles.buttonDisabled : ""].concat(className).join(" ")} type={type} disabled={disabled} onClick={onClick}>{content}</button>
  )
}

export default Tooltip
import React, { useState } from "react";
import styles from "./styles.module.css";
import Header from "../../components/header";
import BackVideo from "../../components/backvideo";
import Button from "../../components/button";
import DialogBox from "./dialog-box";
import DialogBoxForm from "./dialog-form";
import { useAuth } from "../../contexts/AmplifyAuthContext";


export default function Briefing() {
    const { userDetails} = useAuth();
    const [mission1, setMission1] = useState(false);
    const [mission2, setMission2] = useState(false);
    const [mission3, setMission3] = useState(false);

    const [show, setShow] = useState(false);
    //const [userDetails, setUserDetails] = useState()
    const [select, setSelect] = useState(0);

    const NameComponent = () => (
        <>
            Hello <span>{userDetails ? userDetails.firstname : ""}</span>! What issue are you facing?
        </>
    );


    return (
        <>
            <BackVideo videos={["https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee2ad447526b468bdd313c1_JARVIS Gear Website1-transcode.mp4", "https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee2ad447526b468bdd313c1_JARVIS Gear Website1-transcode.webm"]} poster={"https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee2ad447526b468bdd313c1_JARVIS Gear Website1-poster-00001.jpg"} />
            <div className={styles.home}>
                <Header content={"Skypad briefing"} space={"3vh"} />
                <div className={styles.buttons} style={{ opacity: mission1 || mission2 || mission3 ? 0 : 1 }}>
                    <Button
                        content={"MOSES"}
                        onClick={() => {
                            setMission1(true);
                        }}
                    />
                    <Button
                        content={"GRID"}
                        onClick={() => {
                            setMission2(true);
                        }}
                    />
                    <div
                        className={styles.imgContainer}
                        onClick={(e) => {
                            setSelect(1);
                            setShow(true);
                        }}
                    >
                        <img src={require("../../assets/img/MOS_logo_v0008.png")} alt="" />
                    </div>
                    <Button
                        content={"SKYSIM"}
                        onClick={() => {
                            setMission3(true);
                        }}
                    />
                    <Button
                        content={"CONTACT"}
                        onClick={() => {
                            setSelect(2);
                            setShow(true);
                        }}
                    />
                </div>
            </div>
            <DialogBox heading={"MOSES"} content={"MOSES is Skypad’s helpful AI assistant. MOSES can analyze your live data and give you insights to be a better pilot."} state={mission1} setState={setMission1} />
            <DialogBox heading={"GRID"} content={"The GRID is Skypad’s data system that stores and organizes all the users data from every single MOS session."} state={mission2} setState={setMission2} />
            <DialogBox heading={"SKYSIM"} content={"SKYSIM is Skypad’s simulator. Made with the best simulation technology and real time training solutions SKYSIM creates a seamless experience between the digital frontier and the real world."} state={mission3} setState={setMission3} />

            {show && (
                <>
                    {select === 1 && <DialogBoxForm setShow={setShow} label={"organiztion"} path={"mosrequest"} title={"M.O.S."} cta={"READY FOR YOUR MOBILE OPTICAL SYSTEM?"} placeholder={"Enter Organization"} buttonText={"SEND"} />}
                    {select === 2 && <DialogBoxForm setShow={setShow} label={"issue"} path={"issues"} title={"CONTACT"} cta={<NameComponent />} placeholder={"Describe Issue"} buttonText={"SEND"} />}
                </>
            )}

        </>
    );
}



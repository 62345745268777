import styles from "./styles.module.css";
import React, { useEffect, useState } from "react";
import Button from "../button";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAuth } from "../../contexts/AmplifyAuthContext";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Info from "../info";
import { API, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

const Profile = () => {
    const { userDetails, logout, getDetails } = useAuth();
    const [toggle, setToggle] = useState(false);
    const [image, setImage] = useState(null);
    const [imageLink, setImageLink] = useState(null);
    // eslint-disable-next-line
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {

        if (userDetails.img !== "https://gallery.yopriceville.com/var/resizes/Free-Clipart-Pictures/Sun-and-Moon-PNG/White_Moon_PNG_Clipart_Picture.png?m=1507172110" && userDetails.img !== "/moon.png") {
            Storage.get(userDetails.img)
                .then((response) => {
                    setImageLink(response);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [userDetails]);

    const handleSubmit = async (fileblob) => {
        const ID = uuidv4();
        const name = "profile/" + ID + fileblob.name.slice(fileblob.name.lastIndexOf("."));
        try {
            const key = await Storage.put(name, fileblob);
            const tempUser = userDetails;
            tempUser["img"] = key.key;
            API.post("users", "/users", {
                body: tempUser,
            })
                .then((response) => {
                    getDetails();
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log("Error uploading file: ", error);
        }
    };
    return (
        <>
            {userDetails && (
                <>
                    <div
                        className={styles.mooncontainer}
                        onClick={(e) => {
                            navigate("/profile")

                        }}
                    >
                        <img src={imageLink? imageLink : "/moon.png"} className={[styles.moon, styles.iconoutline].join(" ")} alt="Moon Icon"></img>
                    </div>

                    <div className={styles.overlay} style={{ display: toggle? "flex" : "none" }}>
                        <div className={styles.dialogContainer}>
                            <div
                                className={styles.close}
                                onClick={(e) => {
                                    setToggle(false);
                                }}
                            >
                                <AiOutlineCloseCircle className={styles.icon} />
                            </div>

                            <Header content={"Profile"} space={"6vh"} className={styles.profileHeader} />

                            <div className={styles.buttonContainer}>
                                <Info content={userDetails.firstname} />
                                <Info content={userDetails.number} />
                                <div>
                                    <input
                                        type="file"
                                        id="file"
                                        name="file"
                                        style={{ display: "none" }}
                                        onChange={(e) => {
                                            setImage(e.target.files[0]);
                                            handleSubmit(e.target.files[0]);
                                        }}
                                        accept="image/png, image/jpeg, image/jpg , image/gif"
                                    />
                                    <label htmlFor="file">
                                        <img alt="#" src={image? URL.createObjectURL(image) : imageLink? imageLink : "/moon.png"} className={styles.profileImage}></img>
                                    </label>
                                </div>
                                <Button
                                    content={"PERMISSIONS"}
                                    onClick={(e) => {
                                        navigate("/permissions");
                                    }}
                                />
                                <Button
                                    content={"LOG OUT"}
                                    onClick={(e) => {
                                        logout(e, navigate);
                                    }}
                                />
                            </div>

                            <a href="/forgot"><div style={{ color: "#00FEFB", textDecoration: "underline", paddingTop: '2.5vh', paddingBottom: '2.5vh'}} >Reset Password</div></a>
                        </div>
                    </div>
                </>
            )}
            {!userDetails && (
                <>
                    <div
                        className={styles.mooncontainer}
                        onClick={(e) => {
                            navigate("/profile")
                        }}
                    >
                        <img src={"/moon.png"} className={styles.moon} alt="Moon Icon"></img>
                        <div className={styles.outline}></div>
                    </div>

                    <div className={styles.overlay} style={{ display: "none" }}></div>
                </>
            )}
        </>
    );
};

export default Profile;

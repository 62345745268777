import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { GiAerialSignal } from "react-icons/gi";
import { FaShare } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function SessionItem({ data }) {
    const [islive, setIslive] = useState(data.status);
    const [copied, setCopied] = useState(false);

    return (
        <>
            {islive && (
                <div className={styles.session}>
                    <img src={require("../../assets/img/MOS_logo_v0008.png")} alt="" />
                    <div className={styles.allDetails}>
                        <div className={styles.sessionDetails}>
                            <h2>{data.description}'s MOS</h2>
                            
                        </div>
                        <div className={styles.timeDetails}>
                            <font>Started : {new Date(data.createdAt).toLocaleString()}</font>
                        </div>
                        <div className={styles.allActionsMobile}>
                            <div className={styles.islive}>
                                {!data.status && (
                                    <>
                                        <GiAerialSignal style={{ color: "#888", fontSize: "1.5rem", margin: "5px" }} /> <span> Completed </span>
                                    </>
                                )}
                                {data.status && (
                                    <>
                                        <GiAerialSignal style={{ color: "#00FEFB", fontSize: "1.5rem", margin: "5px" }} /> <span>Ongoing</span>
                                    </>
                                )}
                            </div>
                            <div className={styles.action}>
                                <CopyToClipboard
                                    text={`${window.location.hostname}/live/${data.sessionId}`}
                                    style={{ borderColor: "#00FEFB" }}
                                    onCopy={(e) => {
                                        setCopied(true);
                                    }}
                                >
                                    <div>
                                        <FaShare style={{ color: !copied ? "#00FEFB" : "#888", fontSize: "1.5rem", marginBottom: 10, marginTop: 5 }} />

                                        <p>{copied ? "Copied!" : "Share"}</p>
                                    </div>
                                </CopyToClipboard>
                            </div>
                            <a
                                className={styles.action}
                                style={{ borderColor: "#00FEFB" }}
                                // onClick={(e) => {
                                //     e.preventDefault();
                                //     navigate(`/live/${data.sessionId}`)
                                // }}
                                href={`/live/${data.sessionId}`}
                            >
                                <img src={require("../../assets/img/MOS_logo_v0008.png")} alt="" style={{ backgroundColor: "rgba(0,0,0,0.25)", borderRadius: 100, height: "5vh", width: "5vh" }} />
                                <p>View</p>
                            </a>
                        </div>
                    </div>
                    <div className={styles.allActions}>
                        <div className={styles.islive}>
                            {!data.status && (
                                <>
                                    <GiAerialSignal style={{ color: "#888", fontSize: "1.5rem", margin: "5px" }} /> <span> Completed </span>
                                </>
                            )}
                            {data.status && (
                                <>
                                    <GiAerialSignal style={{ color: "#00FEFB", fontSize: "1.5rem", margin: "5px" }} /> <span>Ongoing</span>
                                </>
                            )}
                        </div>

                        <div className={styles.action} style={{ opacity: 0 }}></div>
                        <CopyToClipboard
                            className={styles.action}
                            text={`${window.location.hostname}/live/${data.sessionId}`}
                            style={{ borderColor: "#00FEFB" }}
                            onCopy={(e) => {
                                setCopied(true);
                            }}
                        >
                            <div>
                                <FaShare style={{ color: "#00FEFB", fontSize: "1.5rem", marginBottom: 10, marginTop: 5 }} />
                                <p>{copied ? "Copied!" : "Share"}</p>
                            </div>
                        </CopyToClipboard>

                        <a
                            className={styles.action}
                            style={{ borderColor: "#00FEFB" }}
                            // onClick={(e) => {
                            //     e.preventDefault();
                            //     navigate(`/live/${data.sessionId}`)
                            // }}
                            href={`/live/${data.sessionId}`}
                        >
                            <img src={require("../../assets/img/MOS_logo_v0008.png")} alt="" style={{ backgroundColor: "rgba(0,0,0,0.25)", borderRadius: 100, height: "5vh", width: "5vh", marginBottom: 10 }} />
                            <p>View</p>
                        </a>
                    </div>
                </div>
            )}
        </>
    );
}

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// eslint-disable-next-line
import PrivateRoute from "./PrivateRoute";
import "./index.css";
import "./App.css";
import Home from "./pages/home/Home";
import Signup from "./pages/signup";
import "./App.css";
import MOSHome from "./pages/moshome";
import Contact from "./pages/contact";
import UserJourney from "./pages/userjourney";
import Live from "./pages/live";
import Start from "./pages/start";
import CustomerJourney from "./pages/customer";
import ComingSoon from "./pages/comingsoon/error";
import Controller from "./components/controller";
import Grid from "./pages/grid";
import Announcements from "./pages/announcements";
import Forgot from "./pages/forgot";
import Briefing from "./pages/briefing";
import Permissions from "./pages/permissions";
import Profile from "./pages/profile";

function App() {
    return (
        <div style={{ minHeight: "100vh" }}>
            <Router>
                <Routes>
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <CustomerJourney />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/grid"
                        element={
                            <PrivateRoute>
                                <Grid />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/grid/:from/:to"
                        element={
                            <PrivateRoute>
                                <Grid />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/announcements/:id"
                        element={
                            <PrivateRoute>
                                <Announcements />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/journey"
                        element={
                            <PrivateRoute>
                                <UserJourney />
                            </PrivateRoute>
                        }
                    />
                    <Route path="/" element={<Home />} />
                    <Route path="/start" element={<Start />} />
                    <Route path="/live" element={<Live />} />
                    <Route path="/live/:sid" element={<Live />} />
                    <Route path="/briefing" element={<Briefing />} />
                    <Route path="/home" element={<MOSHome />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/forgot" element={<Forgot />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/permissions" element={<Permissions />} />
                    <Route path="*" element={<ComingSoon />}></Route>
                </Routes>
                <Controller />
            </Router>
        </div>
    );
}

export default App;

import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Header from "../../components/header";
import Button from "../../components/button";
import BackVideo from "../../components/backvideo";
import Moon from "../../components/moon";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { BsPlusCircle } from "react-icons/bs";
import DialogAdd from "./dialog-add";
import { getOrganizationDetails, getUserOrganizations, getUserVehicle } from "../../api";
import { useAuth } from "../../contexts/AmplifyAuthContext";
import DialogAddVehicles from "./dialog-add-vehicles";

export default function Permissions(props) {
    const navigate = useNavigate();
    const { userDetails, authData } = useAuth();
    const [privacy, setPrivacy] = useState(0)
    const [show, setShow] = useState(false)
    const [select, setSelect] = useState(0)
    const [organization, setOrganization] = useState([])
    const [vehicle, setVehicle] = useState([])

    let privacyText = ["Public", "Private"]

    const arr = ["Demo1", "Demo2", "Demo3", "Demo4", "Demo5", "Demo6", "Demo1", "Demo2", "Demo3", "Demo4", "Demo5", "Demo6"]

    async function initialize() {
        const userOrg = await getUserOrganizations(userDetails.uid, authData.signInUserSession.accessToken.jwtToken)
        const userVehicles = await getUserVehicle(userDetails.uid, authData.signInUserSession.accessToken.jwtToken)
        setOrganization(userOrg)
        setVehicle(userVehicles)
    }
    useEffect(() => {
        initialize()

        return () => {

        }
    }, [])


    return (
        <>
            <BackVideo videos={["https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.mp4", "https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.webm"]} poster={"https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-poster-00001.jpg"} />
            <div className={styles.home}>
                <Header content={"permission"} space={"3vh"} />
                <div
                    className={styles.close}
                    onClick={(e) => {
                        console.log("Clicked on close");
                        navigate(-1);
                    }}
                >
                    <IoIosArrowBack className={styles.icon} /> Back
                </div>
                {
                    show &&
                    <>

                        {select === 0 && <DialogAdd title={"Add Organizations"} buttonText={"Add"} setShow={setShow} />}
                        {select === 1 && <DialogAddVehicles token={authData.signInUserSession.accessToken.jwtToken} title={"Add Vehicles"} buttonText={"Add Vehicles"} setShow={setShow} userId={userDetails.uid} initializeParent={initialize}/>}
                    </>
                }
                <div className={styles.container}>
                    <div className={styles.itemContainer2}>
                        <div className={styles.item}>
                            <img src={require("../../assets/img/button/carousel-card-button-256w.png")} alt="" className={styles.buttonTop} />
                            <div className={styles.itemBody}>
                                {/* <div className={styles.plus} onClick={(e) => {
                                    setSelect(0);
                                    setShow(true);
                                }}><BsPlusCircle /></div> */}
                                <div className={styles.scrollBody}>
                                    {
                                        organization.map((item, index) => {
                                            return (
                                                <OrganizationItem key={item.organizationId} id={item.organizationId} token={authData.signInUserSession.accessToken.jwtToken} />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <img src={require("../../assets/img/button/carousel-card-button-256w.png")} alt="" className={styles.buttonBottom} />

                        </div>
                        <div className={styles.item}>
                            <img src={require("../../assets/img/button/carousel-card-button-256w.png")} alt="" className={styles.buttonTop} />
                            <div className={styles.itemBody}>
                                <div className={styles.plus} onClick={(e) => {
                                    setSelect(1);
                                    setShow(true);
                                }}><BsPlusCircle /></div>
                                <div className={styles.scrollBody}>
                                    {
                                        vehicle.map((item, index) => {
                                            return (
                                                <div className={styles.items}>{item.name}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <img src={require("../../assets/img/button/carousel-card-button-256w.png")} alt="" className={styles.buttonBottom} />

                        </div>
                    </div>
                    <div>
                    <Button content={privacyText[privacy]} onClick={() => {
                        setPrivacy(prev => Math.abs(prev - 1))
                    }}></Button>
                    </div>
                </div>
            </div>
        </>
    );
}

function OrganizationItem({ id, token }) {
    const [data, setData] = useState()

    useEffect(() => {
        getOrganizationDetails(id, token)
        .then((res) => { setData(res) })
        .catch((error) => { console.log(error) });
    }, [])

    return (
        <div className={styles.items}>{!!data ? data.organizationName : "Loading .."}</div>
    )
}


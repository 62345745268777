import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "../../components/button";
import { getAllVersions } from "../../aws";
import { Storage } from "aws-amplify";

export default function DialogBoxDownload(props) {
    const [release, setRelease] = useState(null);
    const [url, setUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const { setShow, title, cta, buttonText} = props;

    function compare(a, b) {
        if (parseInt(a.timestamp) < parseInt(b.timestamp)) {
            return -1;
        }
        if (parseInt(a.timestamp) > parseInt(b.timestamp)) {
            return 1;
        }
        return 0;
    }
    const getData = async () => {
        let res = await getAllVersions();
        console.log(res);
        res.sort(compare).reverse();
        setRelease(res[0]);
        let file = await Storage.get(res[0].key.key);
        setUrl(file);
        setLoading(false);
    };

    useEffect(() => {
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.overlay}>
            <div className={styles.dialogContainer}>
                <div
                    className={styles.close}
                    onClick={(e) => {
                        setShow(false);
                    }}
                >
                    <AiOutlineCloseCircle className={styles.icon} />
                </div>
                <font className={styles.heading}>{title}</font>
                {!loading && (
                    <>
                        <div className={styles.innerContainer}>
                            <font className={styles.cta}>{cta}</font>
                            <br />
                            <a href={url ? url : "#"}>
                                <Button content={buttonText} />
                            </a>
                            <font> Version : {release.name}</font>
                        </div>
                    </>
                )}
                {loading && (
                    <div className={styles.innerContainer}>
                        <font className={styles.cta}>Loading ...</font>
                    </div>
                )}
            </div>
        </div>
    );
}

import React from "react";
import BackVideo from "../../components/backvideo";
import styles from "./styles.module.css";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function ComingSoon() {
    const navigate = useNavigate();
    return (
        <>
            <BackVideo videos={["https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.mp4", "https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.webm"]} poster={"https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-poster-00001.jpg"} />
            <div className={styles.container}>
                <div
                    className={styles.close}
                    onClick={(e) => {
                        navigate(-1);
                    }}
                >
                    <IoIosArrowBack className={styles.icon} /> Back
                </div>
                <div className={styles.main}>Coming Soon!</div>
            </div>
        </>
    );
}

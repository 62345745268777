/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:01bf76cf-56ab-40de-a781-ed17da7edacd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_LlTt50QqI",
    "aws_user_pools_web_client_id": "654cninkv1pclf2f5naaop7dgn",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PHONE_NUMBER",
        "PICTURE"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "web4b216-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "organizations-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "announcement-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "forms-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "organizationRelation-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "session-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "users-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "vehicle-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "vehicletype-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "version-staging",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "organization",
            "endpoint": "https://qxf8hoijcf.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "announcement",
            "endpoint": "https://98zaoub4d0.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "forms",
            "endpoint": "https://dk2ha9kpg9.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "organizationRelation",
            "endpoint": "https://5zy208w7k5.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "session",
            "endpoint": "https://bdakbn4c9d.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "users",
            "endpoint": "https://i82rjx8uhb.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "vehicle",
            "endpoint": "https://menysbdu89.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "vehicletype",
            "endpoint": "https://zgk5cov0t5.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "version",
            "endpoint": "https://qbm6d6mdc0.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "milicast",
            "endpoint": "https://wqb8goaf9d.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;

import styles from "./styles.module.css";

import React, { useState } from "react";
import Button from "../button";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAuth } from "../../contexts/AmplifyAuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";

const Moon = (props) => {
    const { login, currentUser, userDetails, logout } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    //searchParams.get("login")


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [toggle, setToggle] = useState(searchParams.get("login") === "true" ? true : false);
    // eslint-disable-next-line
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        setError("");
        const res = await login(email, password);
        console.log(res);

        if (!res.status) {
            console.log("Setting Error");

            setError(res.error);
        }
        console.log("Setting Loading", error);
        setLoading(false);
    }

    return (
        <>
            <div
                className={styles.mooncontainer}
                onClick={(e) => {
                    setToggle(!toggle);
                }}
            >
                <img src="/moon.png" className={styles.moon} alt="Moon Icon"></img>
                <div className={styles.outline}></div>
            </div>

            <div className={styles.overlay} style={{ display: toggle ? "flex" : "none" }}>
                <div className={styles.dialogContainer}>
                    <div
                        className={styles.close}
                        onClick={(e) => {
                            setToggle(false);
                        }}
                    >
                        <AiOutlineCloseCircle className={styles.icon} />
                    </div>
                    {loading && <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}><div className="loader"></div><p>Loading...</p></div>}
                    {!loading && (
                        <>
                            {!currentUser && (
                                <>
                                    <h1>Login</h1>
                                    <p style={{ color: "red" }}>{error}</p>
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        className={styles.input}
                                    />
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                        className={styles.input}
                                    />
                                    <br />
                                    <a href="/forgot" className={styles.forgotText}>
                                        Forgot Password?
                                    </a>

                                    <Button
                                        content={"ENTER"}
                                        onClick={(e) => {
                                            handleSubmit(e);
                                        }}
                                    />

                                     <a href="/signup">
                                        Not a member? <span style={{ color: "#00FEFB", textDecoration: "underline" }}>Sign Up</span>
                                    </a>
                                    {/*
                                    <a href="/contact">
                                    Interested in Skypad? <span style={{ color: "#00FEFB", textDecoration: "underline" }}>Contact HQ</span>
                                    </a>
                                    */}
                                </>
                            )}

                            {!!currentUser && !!userDetails && (
                                <>
                                    <h1 style={{ textAlign: "center" }}>
                                        Welcome <br />
                                        <span style={{ color: "#00FEFB" }}>{userDetails.firstname}</span>
                                    </h1>
                                    {userDetails && userDetails.customer && (
                                        <Button
                                            content={"DASHBOARD"}
                                            onClick={(e) => {
                                                navigate("/dashboard");
                                            }}
                                        />
                                    )}
                                    <Button
                                        content={"JOURNEY"}
                                        onClick={(e) => {
                                            navigate("/journey");
                                        }}
                                    />
                                    <Button
                                        content={"LOGOUT"}
                                        onClick={(e) => {
                                            logout(e, navigate);
                                        }}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Moon;

import styles from "./styles.module.css"

import React, { useEffect, useState } from 'react'
import Button from "../button"

import { useNavigate, useLocation } from "react-router-dom";
import SignupComponent from "../signup/Signup";
import { useAuth } from "../../contexts/AmplifyAuthContext";
import { browserName, isMobile } from 'react-device-detect';

const Controller = (props) => {
  const [toggle, setToggle] = useState(false)
  const [boot, setBoot] = useState(0)
  const { currentUser, userDetails } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setTimeout(async () => {
      await setBoot(null)
      await setBoot(1)
    }, 3400);

  }, [])


  return (
    <div className={styles.controllerPosition}>
      <div className={styles.controllercontainer} style={{ marginBottom: location.pathname === "/home"? "7.5vh" : "1vh", display: location.pathname === "/start"? "none" : "flex" }} >
        <div className={styles.overlay} style={{ background: toggle? 'rgba(0, 0, 0, 0.9)' : 'rgba(0, 0, 0, 0.0)', display: toggle? 'inline-block' : 'none' }} onClick={(e) => { setToggle(!toggle) }}></div>
        {
          (boot === 0) &&
          <video playsInline={true} autoPlay={true} muted={true} loop={true} className={styles.controller} style={{height: location.pathname === "/home"? "17.5vh" : ""}}  onClick={(e) => { setToggle(!toggle) }}>
            { (browserName === "Safari" || isMobile) && <source src={require("../../assets/videos/controller/Logo_controllerBootup_v0003_hevc.mov")} type="video/mp4" />}
            <source src={require("../../assets/videos/controller/Logo_controllerBootup_v0005.webm")} type="video/webm" />
            <source src={require("../../assets/videos/controller/Logo_controllerBootup_v0003_hevc.mov")} type="video/mp4" />

          </video>
        }{
          (boot === 1) &&
          <video playsInline={true} autoPlay={true} muted={true} loop={true} className={styles.controller} style={{height: location.pathname === "/home"? "17.5vh" : "", marginBottom: (location.pathname === "/home" && toggle)? '5vh' : ''}}  onClick={(e) => { setToggle(!toggle) }}>
            { (browserName === "Safari" || isMobile) && <source src={require("../../assets/videos/controller/Logo_controllerLoop_v0003_hevc.mov")} type="video/mp4" />}
            <source src={require("../../assets/videos/controller/Logo_controllerLoop_v0003.webm")} type="video/webm" />
            <source src={require("../../assets/videos/controller/Logo_controllerLoop_v0003_hevc.mov")} type="video/mp4" />
          </video>
        }


        <Button content={"Start"} onClick={(e) => { setToggle(false); navigate("/") }} className={[styles.button, styles.button3, toggle? styles.max : styles.zero]} />
        <Button content={currentUser? "Dashboard" : "Login"} disabled={ currentUser ? !(!!userDetails && userDetails.customer) : false} onClick={(e) => { setToggle(false); navigate(currentUser? "/dashboard" : "/?login=true"); const reloading = !currentUser ? window.location.reload() : null }} className={[styles.button, styles.button4, toggle? styles.max : styles.zero]} />
        <Button content={"Briefing"} onClick={(e) => { setToggle(false); navigate("/briefing") }} className={[styles.button, styles.button5, toggle? styles.max : styles.zero]} />
        {/* <Button content={"Launch"} onClick={(e) => { setToggle(false); navigate("/") }} className={[styles.button, styles.button4, toggle? styles.max : styles.zero]} /> */}
        <Button content={currentUser? "Journey" : "Video"} onClick={(e) => { setToggle(false); navigate(currentUser? "/journey" : "/start") }} className={[styles.button, styles.button2, toggle? styles.max : styles.zero]} />
        <Button content={"Contact HQ"} onClick={(e) => { setToggle(false); navigate("/contact") }} className={[styles.button, styles.button6, toggle? styles.max : styles.zero]} />
        {false &&
          <SignupComponent className={[styles.button7, toggle? styles.max : styles.zero].join(" ")} />
        }
        {
          (!!currentUser && !!userDetails) &&
          <h1 style={{ textAlign: 'center', textWrap: 'nowrap' }} className={[styles.button7, toggle? styles.max : styles.zero].join(" ")}>Hi, <span style={{ color: '#00FEFB', marginLeft: 5 }}>{userDetails.firstname}</span></h1>
        }
      </div>
    </div>

  )
}

export default Controller
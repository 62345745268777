import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { uploadForms } from "../../api";
import AnimatedHeader from "./animated-header";
import Button from "../../components/button";

export default function Contact() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [interest, setInterest] = useState("");
    const [type, setType] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    async function handleSubmit(e) {
        setLoading(true);
        setError("");

        if (email && name && phone && company && interest) {
            console.log();
            const trimmedEmail = email.trim();
            await uploadForms({
                form: "Contact HQ",
                name: name,
                email: trimmedEmail,
                phone: phone,
                company: company,
                interest: interest,
                type: type,
            });
            setSuccess(true);
        } else {
            setError("Enter all the fields.");
        }
        setLoading(false);
    }

    useEffect(() => {
        console.log(phone);
    }, [phone]);

    return (
        <>
            <div className={styles.contact}>
                <div className={styles.menuOverlay}></div>

                {loading && <p>{loading}</p>}
                <AnimatedHeader />
                <div className={styles.container} style={{zIndex: !!email ? 200: 0 }}>
                    <h1>Contact HQ</h1>
                    <br />
                    <br />
                    <br />
                    {!success && (
                        <form onSubmit={handleSubmit} className={styles.login} >
                            {error && <p style={{ color: "red", fontSize: 14, marginBottom: 20 }}>{error}</p>}

                            <input
                                type="email"
                                placeholder="Email Address"
                                className={styles.input}
                                autoFocus={false}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={(e) => {
                                    const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
                                    if (regex.test(e.target.value)) {
                                        console.log("Valid");
                                        setError("");
                                    } else {
                                        console.log("Not valid");
                                        setError("Invalid Email");
                                    }
                                }}
                            />

                            {!!email && (
                                <>
                                    <input type="text" placeholder="Full Name" className={styles.input} autoFocus={false} onChange={(e) => setName(e.target.value)} />

                                    <PhoneInput defaultCountry="US" type="tel" placeholder="Phone Number" className={styles.input} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} autoFocus={false} value={phone} onChange={setPhone} />

                                    <input type="text" placeholder="Company & Position" className={styles.input} autoFocus={false} onChange={(e) => setCompany(e.target.value)} />

                                    <input type="text" placeholder="What is your interest in Skypad?" className={styles.input} autoFocus={false} onChange={(e) => setInterest(e.target.value)} />

                                    {/* <select className={styles.input} onChange={(e) => setType(e.target.value)}>
                                        <option value="">Select one...</option>
                                        <option value="Enterprise">Enterprise</option>
                                        <option value="Adventurers">Adventurers</option>
                                        <option value="Live Audience">Live Audience</option>
                                    </select> */}
                                    <Button
                                        onClick={(e) => {
                                            handleSubmit();
                                        }}
                                        content={!loading ? "Submit" : "Sending"}
                                        disabled={loading}
                                    />
                                </>
                            )}
                        </form>
                    )}
                    {success && <p style={{ color: "#fff" }}>We have successfully received your inquiry!</p>}
                </div>

                <div className={styles.infocontainer}>
                    <div className={styles.info}>
                        <p>
                            Skypad is built with experience from military test pilots, jetpack <br /> test pilots, aerospace engineers, and NASA mixed reality engineers.
                        </p>
                        <p>
                            We exclusively use Epic Games Unreal Engine and Cantina Creative’s <br /> designs to create the next generation of mobility technology... <br />
                            the Mobile Optical System [M.O.S.]
                        </p>
                    </div>

                    <div className={styles.partnerlist}>
                        <div className={styles.partner}>
                            <img src={require("../../assets/img/Group-19.png")} alt="" className={styles.logo} />
                        </div>
                    </div>
                </div>

                <div style={{ height: "30vh" }}></div>
            </div>
        </>
    );
}



import React from "react";
import styles from "./styles.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function VideoPlayer({ url, poster, setDisplay, display }) {
    return (
        <div className={styles.videoplayerContainer}>
            <div style={{ position: "relative" }}>
                <div
                    className={styles.close}
                    onClick={async (e) => {
                        console.log("clicked");
                        await setDisplay(null);
                        await setDisplay(false);
                    }}
                >
                    <AiOutlineCloseCircle className={styles.icon} />
                </div>
                <video autoPlay={true} loop={true} poster={poster} muted={false} playsInline={true} style={{ objectFit: "cover" }} controls={true}>
                    <source src={url} type="video/webm" />
                </video>
            </div>
        </div>
    );
}

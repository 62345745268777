import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import useWindowDimensions from "../../contexts/useWindowDimensions";
import Button from "../../components/button";
import Info from "../../components/info";
import { useAuth } from "../../contexts/AmplifyAuthContext";
import { API, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

export default function CollapseMenu({ setShow, setSelect, navigate, collapse, setCollapse }) {

    const { userDetails, logout, getDetails } = useAuth();
    const [toggle, setToggle] = useState(false);
    const [image, setImage] = useState(null);
    const [imageLink, setImageLink] = useState(null);
    // eslint-disable-next-line
    const [error, setError] = useState("");
    

    useEffect(() => {

        if (userDetails.img !== "https://gallery.yopriceville.com/var/resizes/Free-Clipart-Pictures/Sun-and-Moon-PNG/White_Moon_PNG_Clipart_Picture.png?m=1507172110" && userDetails.img !== "/moon.png") {
            Storage.get(userDetails.img)
                .then((response) => {
                    setImageLink(response);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [userDetails]);

    const handleSubmit = async (fileblob) => {
        const ID = uuidv4();
        const name = "profile/" + ID + fileblob.name.slice(fileblob.name.lastIndexOf("."));
        try {
            const key = await Storage.put(name, fileblob);
            const tempUser = userDetails;
            tempUser["img"] = key.key;
            API.post("users", "/users", {
                body: tempUser,
            })
                .then((response) => {
                    getDetails();
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log("Error uploading file: ", error);
        }
    };

    
    const [sema, setSema] = useState(true);

    const { height, width } = useWindowDimensions();

    useEffect(() => {
        if (width > 480) {
            if (sema !== true) {
                setSema(true);
                setCollapse(false);
            }
        } else {
            if (sema !== false) {
                setSema(false);
                setCollapse(true);
            }
        }
    }, [height, width, sema]);
    return (
        <>
            {!collapse && (
                <div className={styles.navContainer}>
                    <div
                        className={styles.minus}
                        onClick={(e) => {
                            setCollapse(true);
                        }}
                    >
                        -
                    </div>

                    <div className={styles.itemContainer}>
                        <Info content={userDetails.firstname} className={styles.button}/>
                        <Info content={userDetails.number} className={styles.button}/>
                    </div>

                    <div
                        className={styles.imgContainer}
                    >
                        <input
                                        type="file"
                                        id="file"
                                        name="file"
                                        style={{ display: "none" }}
                                        onChange={(e) => {
                                            setImage(e.target.files[0]);
                                            handleSubmit(e.target.files[0]);
                                        }}
                                        accept="image/png, image/jpeg, image/jpg , image/gif"
                                    />
                                    <label htmlFor="file">
                                        <img alt="#" src={image? URL.createObjectURL(image) : imageLink? imageLink : "/moon.png"} className={styles.profileImage}></img>
                                    </label>
                    </div>
                    <div className={styles.itemContainer}>
                        <Button
                            content={"SETTINGS"}
                            className={styles.button}
                            onClick={(e) => {
                                navigate("/settings");
                            }}
                        />
                        <Button
                            content={"LOGOUT"}
                            className={styles.button}
                            onClick={(e) => {
                                logout(e, navigate);
                            }}
                        />
                    </div>
                </div>
            )}
            {collapse && (
                <div className={styles.menuIconContainer} onClick={(e) => setCollapse(false)}>
                    <div className={styles.menuIcon}>
                        <div
                            className={styles.minus}
                            onClick={(e) => {
                                setCollapse(false);
                            }}
                        >
                            +
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

import React, { useState } from "react";
import styles from "./styles.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "../../components/button";

export default function DialogBoxSearch({ setShow, title, placeholder, buttonText, navigate, setToState, setFromState }) {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    const [from, setFrom] = useState(new Date().toISOString().split("T")[0]);
    const [to, setTo] = useState(date.toISOString().split("T")[0]);
    //const [email, setEmail] = useState("");
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(true);

    async function handleSubmit() {
        //navigate(`/grid/${from}/${to}`);
        setToState(to);
        setFromState(from)
        setSubmit(true);
        setShow(false);
        setLoading(false);
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.dialogContainer}>
                <div
                    className={styles.close}
                    onClick={(e) => {
                        setShow(false);
                    }}
                >
                    <AiOutlineCloseCircle className={styles.icon} />
                </div>
                <font className={styles.heading}>{title}</font>
                {!submit && (
                    <>
                        <div className={styles.innerContainer}>
                            <font className={styles.cta}>Pick a date</font>
                            <div className={styles.row}>
                                <input
                                    type="date"
                                    value={from}
                                    onChange={(e) => {
                                        setFrom(e.target.value);
                                    }}
                                    placeholder={"From"}
                                    className={styles.input}
                                    min="2022-01-01"
                                    max={new Date().toISOString().split("T")[0]}
                                />
                                <span style={{ margin: "0px 25px", marginTop: "25px" }}>to</span>
                                <input
                                    type="date"
                                    value={to}
                                    onChange={(e) => {
                                        setTo(e.target.value);
                                    }}
                                    placeholder={"To"}
                                    className={styles.input}
                                    min="2022-01-01"
                                    max={date.toISOString().split("T")[0]}
                                />
                            </div>

                            {/* <font className={styles.cta} style={{ marginTop: "40px" }}>
                                Search email
                            </font>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                placeholder={placeholder}
                                className={styles.input}
                            /> */}
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Button
                                onClick={(e) => {
                                    handleSubmit();
                                }}
                                content={buttonText}
                            />
                        </div>
                    </>
                )}
                {submit && (
                    <>
                        {loading && (
                            <div className={styles.innerContainer}>
                                <font className={styles.cta}>Sending your request ...</font>
                            </div>
                        )}

                        {!loading && (
                            <div className={styles.innerContainer}>
                                <font className={styles.cta}>
                                    We have received your request.
                                    <br />
                                    Thank You!
                                </font>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
import React from "react";
import styles from "./styles.module.css";

export default function Dropdown({ csv, json, telemetry }) {
    return (
        <div className={styles.dropdownContainer}>
            {
                json && (
                    <a
                        href={json}
                        download
                        target={"_blank"}
                        rel={"noreferrer"}
                        style={{ borderBottom: "1px solid #00FEFB" }}
                        onClick={(e) => {
                            window.open(json, "_blank");
                        }}
                    >
                        JSON
                    </a>
                )
            }
            {
                csv && (
                    <a
                        href={csv}
                        download
                        target={"_blank"}
                        rel={"noreferrer"}
                        onClick={(e) => {
                            window.open(csv, "_blank");
                        }}
                    >
                        CSV
                    </a>
                )
            }

{
                telemetry && (
                    <a
                        href={telemetry}
                        download
                        target={"_blank"}
                        rel={"noreferrer"}
                        onClick={(e) => {
                            window.open(telemetry, "_blank");
                        }}
                    >
                        Download
                    </a>
                )
            }
        </div>
    );
}
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import BackVideo from "../../components/backvideo";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
export default function Start (props) {
  const [one, setOne] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      setOne(false)
    }, 4000);
    setTimeout(() => {
      navigate("/contact")
    }, 92000);
    // eslint-disable-next-line
  }, [])

  return (
    <>
    {one &&
    <BackVideo 
    videos={[
      require("../../assets/videos/MOS_logo_v0008-transcode.mp4"),
      require("../../assets/videos/MOS_logo_v0008-transcode.webm"),
    ]}

    poster={require("../../assets/videos/MOS_logo_v0008-poster-00001.jpg")}
    />
  }
    <div className={styles.home} style={{display: one? 'none':'flex'}}>
    <iframe src="https://www.youtube.com/embed/76slXC1u4XU?autoplay=1&color=white&disablekb=1&fs=0&modestbranding=0&rel=0" title="Skypad: Launch Video" frameborder="0" loading="lazy" className={styles.video} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen=""></iframe>
      
      <div className={styles.container}>
      <Button content={"Back"} className={styles.button} onClick={(e) => { navigate("/") }} />
      <Button content={"Next"} className={styles.button} onClick={(e) => { navigate("/contact") }} />
      </div>
    </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "../../components/button";
import { addUserVehicle, getVehicleTypes } from "../../api";

export default function DialogAddVehicles({
    setShow,
    title,
    buttonText,
    token,
    userId,
    initializeParent
}) {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    const [from, setFrom] = useState(new Date().toISOString().split("T")[0]);
    const [to, setTo] = useState(date.toISOString().split("T")[0]);
    //const [email, setEmail] = useState("");
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState();
    const [type, setType] = useState();
    const [vehiclelist, setVehiclelist] = useState([]);

    async function initialize() {
        console.log(token);
        const data = await getVehicleTypes(token);
        console.log("Vehicles", data);
        setVehiclelist(data);
        setType(data[0].id)
    }

    useEffect(() => {
        initialize();
    }, []);

    async function handleSubmit() {
        if (!!name && !!type) {
            await addUserVehicle(name, userId, type, token);
            console.log("Submit", name, userId, type, token);
            alert("Vehicle has been successfully added.");
            setShow(false);
            initializeParent();
        } else {
            alert("Please provide all vehicle information.");
        }
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.dialogContainer}>
                <div
                    className={styles.close2}
                    onClick={(e) => {
                        setShow(false);
                    }}
                >
                    <AiOutlineCloseCircle className={styles.icon} />
                </div>
                <font className={styles.heading}>{title}</font>
                {!submit && (
                    <>
                        <div className={styles.innerContainer}>
                            <select
                                className={styles.input}
                                value={type}
                                onChange={(e) => {
                                    setType(e.target.value);
                                }}
                            >
                                {vehiclelist.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.manufacturer} {item.model}
                                    </option>
                                ))}
                            </select>
                            <input
                                type="email"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                                placeholder={"Enter a nickname"}
                                className={styles.input}
                            />
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Button
                                onClick={(e) => {
                                    handleSubmit();
                                }}
                                content={buttonText}
                            />
                        </div>
                    </>
                )}
                {submit && (
                    <>
                        {loading && (
                            <div className={styles.innerContainer}>
                                <font className={styles.cta}>
                                    Sending your request ...
                                </font>
                            </div>
                        )}

                        {!loading && (
                            <div className={styles.innerContainer}>
                                <font className={styles.cta}>
                                    We have received your request.
                                    <br />
                                    Thank You!
                                </font>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

import React, { useState } from "react";
import styles from "./styles.module.css";
import { useAuth } from "../../contexts/AmplifyAuthContext";
import { uploadForms } from "../../api";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "../../components/button";

export default function DialogBoxForm(props) {
    const { userDetails } = useAuth();
    const [data, setData] = useState("");
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const { setShow, title, cta, placeholder, buttonText } = props;

    async function handleSubmit() {
        setSubmit(true);
        const name = userDetails.firstname + " " + userDetails.lastname;
        const query = {
            form: title,
            uid: userDetails.uid,
            name: name,
            email: userDetails.email,
            query: data,
        };
        console.log(query);
        try {
            const result = await uploadForms(query);
            console.log(result);
        } catch (e) {
            console.log(e);
        }

        setLoading(false);
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.dialogContainer}>
                <div
                    className={styles.close}
                    onClick={(e) => {
                        setShow(false);
                    }}
                >
                    <AiOutlineCloseCircle className={styles.icon} />
                </div>
                <font className={styles.heading}>{title}</font>
                {!submit && (
                    <>
                        <div className={styles.innerContainer}>
                            <font className={styles.cta}>{cta}</font>
                            <input
                                type="text"
                                value={data}
                                onChange={(e) => {
                                    setData(e.target.value);
                                }}
                                placeholder={placeholder}
                                className={styles.input}
                            />
                        </div>
                        <Button
                            onClick={(e) => {
                                handleSubmit();
                            }}
                            content={buttonText}
                        />
                    </>
                )}
                {submit && (
                    <>
                        {loading && (
                            <div className={styles.innerContainer}>
                                <font className={styles.cta}>Sending your request ...</font>
                            </div>
                        )}

                        {!loading && (
                            <div className={styles.innerContainer}>
                                <font className={styles.cta}>
                                    We have received your request.
                                    <br />
                                    Thank You!
                                </font>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import useWindowDimensions from "../../contexts/useWindowDimensions";
import Button from "../../components/button";

export default function CollapseMenu({ setShow, setSelect, navigate }) {
    const [collapse, setCollapse] = useState(false);
    const [sema, setSema] = useState(true);
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        if (width > 480) {
            if (sema !== true) {
                setSema(true);
                setCollapse(false);
            }
        } else {
            if (sema !== false) {
                setSema(false);
                setCollapse(true);
            }
        }
    }, [height, width, sema]);

    return (
        <>
            {!collapse && (
                <div className={styles.navContainer}>
                    <div
                        className={styles.minus}
                        onClick={(e) => {
                            setCollapse(true);
                        }}
                    >
                        -
                    </div>
                    <div className={styles.itemContainer}>
                        <Button
                            content={"SEARCH"}
                            className={styles.button}
                            onClick={(e) => {
                                setSelect(0);
                                setShow(true);
                            }}
                        />
                        <Button
                            content={"GRID"}
                            className={styles.button}
                            onClick={(e) => {
                                navigate("/grid");
                            }}
                        />
                    </div>

                    <div
                        className={styles.imgContainer}
                        onClick={(e) => {
                            setSelect(2);
                            setShow(true);
                        }}
                    >
                        <img src={require("../../assets/img/MOS_logo_v0008.png")} alt="" />
                    </div>
                    <div className={styles.itemContainer}>
                        <Button
                            content={"JOURNEY"}
                            className={styles.button}
                            onClick={(e) => {
                                navigate("/journey");
                            }}
                        />
                        <Button
                            content={"CONTACT"}
                            className={styles.button}
                            onClick={(e) => {
                                setSelect(4);
                                setShow(true);
                            }}
                        />
                    </div>
                </div>
            )}
            {collapse && (
                <div className={styles.menuIconContainer} onClick={(e) => setCollapse(false)}>
                    <div className={styles.menuIcon}>
                        <div
                            className={styles.minus}
                            onClick={(e) => {
                                setCollapse(false);
                            }}
                        >
                            +
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Header from "../../components/header";
import BackVideo from "../../components/backvideo";
import Button from "../../components/button";
import { useAuth } from "../../contexts/AmplifyAuthContext";
import Profile from "../../components/profile";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Announcements from "../../components/announcements";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { editSession, getSettings, liveSession } from "../../api";


import DialogBoxGF from "./dialog-google";
import DialogBoxForm from "./dialog-form";
import SessionItem from "./session-item";
import CollapseMenu from "./collapse-menu";

export default function UserJourney() {
    const { userDetails, authData } = useAuth();
    const [show, setShow] = useState(false);
    //const [userDetails, setUserDetails] = useState()
    const [select, setSelect] = useState(0);
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [name, setName] = useState(userDetails ? userDetails.firstname : "");
    const [links, setLinks] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [display, setDisplay] = useState();
    const [loading, setLoading] = useState(true);

    const NameComponent = () => (
        <>
            Hello <span>{userDetails ? userDetails.firstname : ""}</span>! What issue are you facing?
        </>
    );

    async function getData() {
        const data = {"creators":"https://docs.google.com/forms/d/e/1FAIpQLSd-IPmSabH_aLzhd2Vwmin3izDpToI1n-yFSknVlzp4kUOWCg/viewform?embedded=true","investors":"https://docs.google.com/forms/d/e/1FAIpQLSfc6eHsPiAUwgOSINiHihcaLSeaeGNzquaV0WefidAO-PLcDw/viewform?embedded=true"}
        setLinks(data);
    }

    function compare(a, b) {
        if (new Date(a.createdAt) < new Date(b.createdAt)) {
            return -1;
        }
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        getData();
        liveSession(authData.signInUserSession.accessToken.jwtToken)
            .then(async (res) => {
                console.log(res)
                let data = res.sort(compare).filter((a) => {
                    return !!a;
                });
                setSessions(data.reverse());
                setDisplay(data.slice(0, 10));
                // data.forEach((item)=>{
                //     editSession(item, authData.signInUserSession.accessToken.jwtToken)
                // })
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <BackVideo videos={["https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.mp4", "https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.webm"]} poster={"https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-poster-00001.jpg"} />
            {!!userDetails && (
                <>
                    {show && (
                        <>
                            {select === 0 && <DialogBoxGF setShow={setShow} title={"CREATORS"} src={!!links ? links["creators"] : "#"} />}
                            {select === 1 && <DialogBoxGF setShow={setShow} title={"INVESTORS"} src={!!links ? links["investors"] : "#"} />}
                            {select === 2 && <DialogBoxForm setShow={setShow} label={"organiztion"} path={"mosrequest"} title={"M.O.S."} cta={"READY FOR YOUR MOBILE OPTICAL SYSTEM?"} placeholder={"Enter Organization"} buttonText={"SEND"} />}
                            {select === 3 && <DialogBoxForm setShow={setShow} title={"PROFILE"} cta={"INTERESTED IN SKYPAD'S ANNUAL EVENT?"} placeholder={"Enter Your Email"} buttonText={"SEND"} />}
                            {select === 4 && <DialogBoxForm setShow={setShow} label={"issue"} path={"issues"} title={"CONTACT"} cta={<NameComponent />} placeholder={"Describe Issue"} buttonText={"SEND"} />}
                        </>
                    )}
                    <div className={styles.home}>
                        <Profile/>
                        {/* <Announcements /> */}
                        <Header content={"Journey"} space={"6vh"} />
                        <CollapseMenu navigate={navigate} setShow={setShow} setSelect={setSelect} />
                        <div className={styles.mosList}>
                            {!!userDetails && sessions.length > 0 && (
                                <>
                                    {!loading && (
                                        <>
                                            {display.map((session, key) => {
                                                const data = session;
                                                return <SessionItem data={data}/>;
                                            })}
                                            {display.length < sessions.length && (
                                                <div>
                                                    <Button
                                                        onClick={(e) => {
                                                            const diff = sessions.length - display.length;
                                                            if (diff > 10) {
                                                                setDisplay(sessions.slice(0, display.length + 10));
                                                            } else {
                                                                setDisplay(sessions);
                                                            }
                                                        }}
                                                        content={"Load More"}
                                                        className={styles.loadButton}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {loading && <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontWeight: 300 }}> <div className="loader"></div> {"Loading Videos & Telemetry..."}</div>}
                                </>
                            )}
                            {!(!!userDetails && sessions.length > 0) && (
                                <>
                                    <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontWeight: 300 }}> {!loading ? "Waiting for M.O.S. on Skypad.Live ..." : "Loading MOS Sessions.."}</div>
                                </>
                            )}
                            <div style={{ marginTop: "2vw" }}></div>
                        </div>
                    </div>

                    <div style={{ height: "30vh" }}></div>
                </>
            )}
        </>
    );
}


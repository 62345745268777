import React, { useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AmplifyAuthContext";
import BackVideo from "../../components/backvideo";
import Button from "../../components/button";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "react-phone-number-input/style.css";

export default function Forgot() {
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    // eslint-disable-next-line
    const [error, setError] = useState("");
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [otp, setOtp] = useState("");
    const navigate = useNavigate();
    const { resetPassword, resetPasswordSubmit } = useAuth();

    async function handleSubmit(e) {
        setLoading(true);
        e.preventDefault();
        setError("");

        if (email) {
            console.log();
            const trimmedEmail = email.trim();
            if (validEmail) {
                const res = await resetPassword(trimmedEmail);
                console.log(res);
                if (res.status) {
                    setConfirm(true);
                } else {
                    setError(res.error);
                }
            } else {
                setError("Please enter a valid email");
            }
        } else {
            setError("Enter all the fields.");
        }
        setLoading(false);
    }

    async function handleOTP(e) {
        try {
            const trimmedEmail = email.trim();
            if (password === repeatPassword) {
                const res = await resetPasswordSubmit(trimmedEmail, otp, password);
                if (res.status) {
                    alert("Password Updated");
                    navigate("/journey");
                } else {
                    setError("Unable to confirm");
                }
            } else {
                setError("Passwords do not match");
            }
        } catch (err) {
            setError(err);
        }
    }

    return (
        <>
            <BackVideo videos={["https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.mp4", "https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.webm"]} poster={"https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-poster-00001.jpg"} />

            <div className={styles.signup}>
                {loading && <p>{loading}</p>}
                {!confirm && (
                    <div className={styles.container}>
                        <div className={styles.close} onClick={(e) => {
                            navigate("/?login=true")
                        }}>
                            <AiOutlineCloseCircle className={styles.icon} />
                        </div>
                        <h1>Forgot Password</h1>
                        <br />
                        <br />
                        <br />
                        <div className={styles.login}>
                            {error && <p style={{ color: "red", fontSize: 14, marginBottom: 20 }}>{error}</p>}

                            <input
                                type="email"
                                placeholder="Email"
                                className={styles.input}
                                autoFocus={false}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                onBlur={(e) => {
                                    const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
                                    if (regex.test(e.target.value)) {
                                        console.log("Valid");
                                        setError("");
                                        setValidEmail(true);
                                    } else {
                                        console.log("Not valid");
                                        setError("Invalid Email");
                                        setValidEmail(false);
                                    }
                                }}
                            />

                            <Button content="Reset" className={styles.btn} onClick={(e) => handleSubmit(e)} />
                            <a href="/?login=true" style={{ fontSize: "1.5vh" }}>
                                Already a member? <span style={{ color: "#00FEFB", textDecoration: "underline" }}>Sign In Now</span>
                            </a>
                        </div>
                    </div>
                )}
                {confirm && (
                    <div className={styles.container} style={{ justifyContent: "center" }}>
                        <h1>Reset your password</h1>
                        <p>Check your email for Verification Code</p>
                        <br />
                        <br />
                        <br />
                        <div className={styles.login}>
                            {error && <p style={{ color: "red", fontSize: 14, marginBottom: 20 }}>{error}</p>}

                            <input type="password" placeholder="Verification Code" className={styles.input} autoFocus={false} value={otp} onChange={(e) => setOtp(e.target.value)} />
                        </div>
                        <div className={styles.login}>
                            <input type="password" placeholder="Password" className={styles.input} autoFocus={false} onChange={(e) => setPassword(e.target.value)} />

                            <input type="password" placeholder="Confirm Password" className={styles.input} autoFocus={false} onChange={(e) => setRepeatPassword(e.target.value)} />

                            <Button content="Update" className={styles.btn} onClick={(e) => handleOTP(e)} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

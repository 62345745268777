import styles from "./styles.module.css"

import React from 'react'

const Button = ({content, onClick, className, type, disabled, style}) => {

  return (
    <button style={style} className={[styles.button, !!disabled ? styles.buttonDisabled : ""].concat(className).join(" ")} type={type} disabled={disabled} onClick={onClick}>{content}</button>
  )
}

export default Button
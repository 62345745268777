import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Header from "../../components/header";
import BackVideo from "../../components/backvideo";
import Button from "../../components/button";
import { useAuth } from "../../contexts/AmplifyAuthContext";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Announcements from "../../components/announcements";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { deleteUserVehicle, editSession, getSettings, liveSession } from "../../api";
import { getOrganizationDetails, getUserOrganizations, getUserVehicle } from "../../api";
import { IoIosArrowBack } from "react-icons/io";
import { BsPlusCircle } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";


import DialogBoxGF from "./dialog-google";
import DialogBoxForm from "./dialog-form";
import SessionItem from "./session-item";
import CollapseMenu from "./collapse-menu";
import DialogAdd from "./dialog-add";
import DialogAddVehicles from "./dialog-add-vehicles";

export default function Profile() {
    const { userDetails, authData } = useAuth();
    const [show, setShow] = useState(false);
    //const [userDetails, setUserDetails] = useState()
    const [select, setSelect] = useState(0);
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [name, setName] = useState(userDetails ? userDetails.firstname : "");
    const [links, setLinks] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [display, setDisplay] = useState();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([])
    const [organization, setOrganization] = useState([])
    const [vehicle, setVehicle] = useState([])
    const [privacy, setPrivacy] = useState(0)
    const [collapse, setCollapse] = useState(false);
    let privacyText = ["Public", "Private"]

    const NameComponent = () => (
        <>
            Hello <span>{userDetails ? userDetails.firstname : ""}</span>! What issue are you facing?
        </>
    );

    async function getData() {
        const data = await getSettings();
        setLinks(data);
    }

    function compare(a, b) {
        if (new Date(a.createdAt) < new Date(b.createdAt)) {
            return -1;
        }
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
            return 1;
        }
        return 0;
    }

    async function initialize() {
        const userOrg = await getUserOrganizations(userDetails.uid, authData.signInUserSession.accessToken.jwtToken)
        const userVehicles = await getUserVehicle(userDetails.uid, authData.signInUserSession.accessToken.jwtToken)
        setOrganization(userOrg)
        setVehicle(userVehicles)
        console.log(userVehicles)
        setLoading(false);
    }

    useEffect(() => {
        getData();
        initialize();
        liveSession(authData.signInUserSession.accessToken.jwtToken)
            .then(async (res) => {
                let data = res.sort(compare).filter((a) => {
                    return !!a;
                });
                setSessions(data.reverse());
                setDisplay(data.slice(0, 10));
                // data.forEach((item)=>{
                //     editSession(item, authData.signInUserSession.accessToken.jwtToken)
                // })
                
            })
            .catch((err) => {
                console.log(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <BackVideo videos={["https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.mp4", "https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.webm"]} poster={"https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-poster-00001.jpg"} />
            {!!userDetails && (
                <>
                    {show &&
                        <>

                            {select === 0 && <DialogAdd title={"Add Organizations"} buttonText={"Add"} setShow={setShow} />}
                            {select === 2 && <DialogAdd title={"Add Users"} buttonText={"Add"} setShow={setShow} />}
                            {select === 1 && <DialogAddVehicles token={authData.signInUserSession.accessToken.jwtToken} title={"Add Vehicles"} buttonText={"Add Vehicles"} setShow={setShow} userId={userDetails.uid} initializeParent={initialize} />}
                        </>
                    }
                    <div className={styles.home}>
                        {/* <Announcements /> */}
                        <Header content={"Profile"} space={"6vh"} />
                        <CollapseMenu navigate={navigate} setShow={setShow} setSelect={setSelect} setCollapse={setCollapse} collapse={collapse}/>
                        <div className={styles.mosList} style={{height: collapse ? '60vh': undefined, marginTop: collapse ? '-11vh': undefined}}>
                            <div className={styles.itemContainer2}>
                                <div className={styles.item}>
                                    <img src={require("../../assets/img/button/carousel-card-button-256w.png")} alt="" className={styles.buttonTop} />
                                    <div className={styles.itemBody}>
                                        {/* <div className={styles.plus} onClick={(e) => {
                                            setSelect(2);
                                            setShow(true);
                                        }}><BsPlusCircle /></div> */}
                                        <div className={styles.scrollBody}>
                                            {
                                                users.map((item, index) => {
                                                    return (
                                                        <div className={styles.items}>{item.name}</div>
                                                    )
                                                })
                                            }
                                            {
                                                users.length === 0 && (
                                                    loading ? <div className={styles.items}>Loading ...</div> : <div className={styles.items}>No Users Found</div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <img src={require("../../assets/img/button/carousel-card-button-256w.png")} alt="" className={styles.buttonBottom} />

                                </div>
                                <div className={styles.item}>
                                    <img src={require("../../assets/img/button/carousel-card-button-256w.png")} alt="" className={styles.buttonTop} />
                                    <div className={styles.itemBody}>
                                        <div className={styles.plus} onClick={(e) => {
                                            setSelect(1);
                                            setShow(true);
                                        }}><BsPlusCircle /></div>
                                        <div className={styles.scrollBody}>
                                            {
                                                vehicle.map((item, index) => {
                                                    return (
                                                        <div className={styles.items}>
                                                            {item.name} 
                                                            <AiOutlineCloseCircle className={styles.iconClose} onClick={(e)=>{
                                                                console.log("Deleting Vehicle " + item.id)
                                                                deleteUserVehicle(authData.signInUserSession.accessToken.jwtToken, item.id, userDetails.uid)
                                                                initialize()
                                                            }}/></div>
                                                    )
                                                })
                                            }
                                            {
                                                vehicle.length === 0 && (
                                                    loading ? <div className={styles.items}>Loading ...</div> : <div className={styles.items}>No Vehicles Found</div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <img src={require("../../assets/img/button/carousel-card-button-256w.png")} alt="" className={styles.buttonBottom} />

                                </div>
                                <div className={styles.item}>
                                    <img src={require("../../assets/img/button/carousel-card-button-256w.png")} alt="" className={styles.buttonTop} />
                                    <div className={styles.itemBody}>
                                        {/* <div className={styles.plus} onClick={(e) => {
                                    setSelect(0);
                                    setShow(true);
                                }}><BsPlusCircle /></div> */}
                                        <div className={styles.scrollBody}>
                                            {
                                                organization.map((item, index) => {
                                                    return (
                                                        <OrganizationItem key={item.organizationId} id={item.organizationId} token={authData.signInUserSession.accessToken.jwtToken} />
                                                    )
                                                })
                                            }

{
                                                organization.length === 0 && (
                                                    loading ? <div className={styles.items}>Loading ...</div> : <div className={styles.items}>No Organization Found</div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <img src={require("../../assets/img/button/carousel-card-button-256w.png")} alt="" className={styles.buttonBottom} />

                                </div>
                                

                            </div>
                            <div>
                                <Button content={privacyText[privacy]} onClick={() => {
                                    setPrivacy(prev => Math.abs(prev - 1))
                                }} style={{filter: privacyText[privacy] === "Private" ? 'grayscale(80%)' : 'grayscale(0%)' }}></Button>
                            </div>
                            <div style={{ marginTop: "2vw" }}></div>
                        </div>
                    </div>

                    <div style={{ height: "30vh" }}></div>
                </>
            )}
        </>
    );
}

function OrganizationItem({ id, token }) {
    const [data, setData] = useState()

    useEffect(() => {
        getOrganizationDetails(id, token)
            .then((res) => { setData(res) })
            .catch((error) => { console.log(error) });
    }, [])

    return (
        <div className={styles.items}>{!!data ? data.organizationName : "Loading .."}</div>
    )
}
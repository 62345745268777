import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";

export default function AnimatedHeader() {
    const [state, setState] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setState(true);
        }, 4000);
    }, []);

    if (!state) {
        return (
            <div className={styles.header}>
                <video autoPlay={true} loop={true} muted={true} playsInline={true} style={{ height: "inherit", width: "inherit", objectFit: "cover" }}>
                    <source src={require("../../assets/videos/MOS_logo_v0008-transcode.webm")} />
                    <source src={require("../../assets/videos/MOS_logo_v0008-transcode.mp4")} />
                </video>
            </div>
        );
    } else {
        return (
            <div className={styles.header}>
                <img src={require("../../assets/img/sky_pad.png")} alt="logo" style={{ height: "10vh", width: "inherit", objectFit: "contain" }} />
            </div>
        );
    }
}